<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "HMap",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    data: function () {
        return {
            options: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
            },
            center: {},
            zoom: 0,
            type: 'terrain',

            infoOptions: {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
        };
    },
    methods: {
        select(item) {
            this.zoom = 17;
            this.center = item.position;

            this.infoWinOpen = !!item.name;
            this.infoWindowPos = item.position;
            this.infoOptions.content = '<b>' + item.name + '</b><br/>' + item.adresse;
        },
        reset() {
            this.zoom = 15;
            this.center = {lat: 53.53374301093393, lng: 10.126346587376023};
        },
    },
    mounted() {
        this.reset();
    },
})
</script>

<template>
    <GmapMap
        :options="options"
        :center="center"
        :zoom="zoom"
        :map-type-id="type"
        style="width: 100%; height: 100%"
    >
        <GmapCluster
            :maxZoom="15"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in value"
                :position="m.position"
                clickable
                @click="select(m)"
            />
        </GmapCluster>

        <GmapInfoWindow
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false"
        />
    </GmapMap>
</template>

<style scoped>

</style>