<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "HSearch",
    methods: {
        setPlace(place) {
            if (!place) return;

            this.$emit('input', {
                position: {
                    lat: place.latitude,
                    lng: place.longitude,
                }
            });
        },
    },
})
</script>

<template>
    <v-toolbar
        absolute
        dense
        flat
        color="transparent"
        width="500"
        max-width="100%"
    >
        <v-flex>
            <vuetify-google-autocomplete
                solo
                hide-details
                clearable
                id="search"
                label="Adresse suchen"
                append-icon="mdi-magnify"
                v-on:placechanged="setPlace"
            />

            <v-text-field style="display: none;"/>
        </v-flex>
    </v-toolbar>
</template>

<style scoped>
/deep/ .v-toolbar__content {
    padding: 0 !important;
}
</style>