import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

Vue.component('GmapCluster', GmapCluster)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    libraries: 'places',
    key: 'AIzaSyAVwiHhq0frBJHQrZl0CFKuDHczllYgIhg',
    region: 'DE',
    language: 'de',
  },
})

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
