<template>
    <v-app>
        <h-list :items="hydranten" @select="select($event)"/>

        <v-main>
            <v-layout d-flex fill-height>
                <h-map ref="map" :value="hydranten"/>
                <h-search @input="select($event)" class="pa-3"/>
            </v-layout>
        </v-main>
    </v-app>
</template>

<script>
import HMap from "@/components/HMap.vue";
import {HYDRANTEN} from "@/hydranten";
import HSearch from "@/components/HSearch.vue";
import HList from "@/components/HList.vue";

export default {
    name: 'App',
    components: {HList, HSearch, HMap},
    computed: {
        hydranten() {
            return HYDRANTEN;
        },
    },
    methods: {
        select(item) {
            !item ? this.$refs.map.reset() : this.$refs.map.select(item);
        },
    },
};
</script>
