var utmObj = require('utm-latlng');
var utm = new utmObj();

export const HYDRANTEN = [
    {
        "id": "",
        "easting": "571132.374",
        "northing": "5931681.952",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 46
    },
    {
        "id": "",
        "easting": "570512.592",
        "northing": "5929212.73",
        "plz": 22113,
        "ortsteil": "Rothenburgsort",
        "str": "Moorfleeter Hauptdeich",
        "nr": 81
    },
    {
        "id": "H11023",
        "easting": "571277.448",
        "northing": "5929498.147",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 181
    },
    {
        "id": "H11003",
        "easting": "571417.047",
        "northing": "5929190.054",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 210
    },
    {
        "id": "H14211",
        "easting": "574601.674",
        "northing": "5932323.639",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Borchardsheide",
        "nr": 12
    },
    {
        "id": "H14216",
        "easting": "574586.622",
        "northing": "5932397.817",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Borchardsheide",
        "nr": 22
    },
    {
        "id": "H13018",
        "easting": "572791.734",
        "northing": "5930262.07",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 48
    },
    {
        "id": "H10975",
        "easting": "571682.069",
        "northing": "5928904.753",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 0
    },
    {
        "id": "H10971",
        "easting": "571668.532",
        "northing": "5928677.388",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 4
    },
    {
        "id": "H11815",
        "easting": "575467.276",
        "northing": "5932414.779",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Erhard-Dressel-Bogen",
        "nr": 14
    },
    {
        "id": "H10955",
        "easting": "571792.918",
        "northing": "5928413.863",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 272
    },
    {
        "id": "H13061",
        "easting": "571392.311",
        "northing": "5931150.564",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 32
    },
    {
        "id": "H13077",
        "easting": "571817.17",
        "northing": "5931170.769",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 15
    },
    {
        "id": "H12284",
        "easting": "570906.678",
        "northing": "5931068.456",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 1
    },
    {
        "id": "H13079",
        "easting": "571769.685",
        "northing": "5931191.863",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 10
    },
    {
        "id": "H13109",
        "easting": "571794.884",
        "northing": "5931481.672",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 5
    },
    {
        "id": "H14208",
        "easting": "574589.409",
        "northing": "5932193.46",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 11
    },
    {
        "id": "H14232",
        "easting": "574502.39",
        "northing": "5932230.434",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 73
    },
    {
        "id": "H13104",
        "easting": "571679.028",
        "northing": "5931474.565",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 1
    },
    {
        "id": "H14217",
        "easting": "574569.012",
        "northing": "5932486.989",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Druckerstraße",
        "nr": 61
    },
    {
        "id": "H19563",
        "easting": "571787.854",
        "northing": "5932524.895",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 8
    },
    {
        "id": "H13063",
        "easting": "571316.03",
        "northing": "5931229.211",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 28
    },
    {
        "id": "H11804",
        "easting": "574930.807",
        "northing": "5932697.602",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Amrumer Knick",
        "nr": 7
    },
    {
        "id": "H12994",
        "easting": "572676.79",
        "northing": "5930305.304",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 40
    },
    {
        "id": "H589",
        "easting": "573089.676",
        "northing": "5930030.321",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "KLGV.601 Billbrook Dorf",
        "nr": 49
    },
    {
        "id": "H4025",
        "easting": "577448.043",
        "northing": "5932529.181",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Stormarnstraße",
        "nr": 0
    },
    {
        "id": "H10989",
        "easting": "571989.087",
        "northing": "5929572.206",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 102
    },
    {
        "id": "H14967",
        "easting": "571192.744",
        "northing": "5932430.443",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 5
    },
    {
        "id": "H14243",
        "easting": "574221.901",
        "northing": "5932295.453",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Arndesstieg",
        "nr": 7
    },
    {
        "id": "H14246",
        "easting": "574127.24",
        "northing": "5932213.247",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Korverweg",
        "nr": 18
    },
    {
        "id": "H13022",
        "easting": "572795.513",
        "northing": "5930262.62",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 48
    },
    {
        "id": "H19500",
        "easting": "572313.499",
        "northing": "5932608.704",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 107
    },
    {
        "id": "H13008",
        "easting": "572748.718",
        "northing": "5930564.974",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 34
    },
    {
        "id": "H11033",
        "easting": "571249.742",
        "northing": "5929874.246",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 135
    },
    {
        "id": "H11787",
        "easting": "575029.128",
        "northing": "5932892.086",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 181
    },
    {
        "id": "H12443",
        "easting": "572105.905",
        "northing": "5930492.101",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 56
    },
    {
        "id": "H12434",
        "easting": "572338.395",
        "northing": "5930136.935",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H12441",
        "easting": "572164.976",
        "northing": "5930411.177",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 60
    },
    {
        "id": "H11778",
        "easting": "575248.489",
        "northing": "5932993.683",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 197
    },
    {
        "id": "H11779",
        "easting": "575155.702",
        "northing": "5932949.228",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 187
    },
    {
        "id": "H12999",
        "easting": "572834.594",
        "northing": "5930506.855",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 14
    },
    {
        "id": "H13066",
        "easting": "571238.877",
        "northing": "5931292.502",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 24
    },
    {
        "id": "H10739",
        "easting": "571314.15",
        "northing": "5928491.273",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 24
    },
    {
        "id": "H10738",
        "easting": "571418.507",
        "northing": "5928484.714",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 12
    },
    {
        "id": "H13141",
        "easting": "571032.619",
        "northing": "5931471.539",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 54
    },
    {
        "id": "H18435",
        "easting": "572147.33",
        "northing": "5932718.725",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 100
    },
    {
        "id": "H19499",
        "easting": "572232.158",
        "northing": "5932672.762",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 100
    },
    {
        "id": "H19496",
        "easting": "572015.087",
        "northing": "5932767.55",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 74
    },
    {
        "id": "H14201",
        "easting": "574759.441",
        "northing": "5932089.952",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 101
    },
    {
        "id": "H14251",
        "easting": "574164.562",
        "northing": "5932460.684",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 27
    },
    {
        "id": "H14281",
        "easting": "574089.001",
        "northing": "5932508.029",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 22
    },
    {
        "id": "H14245",
        "easting": "574286.553",
        "northing": "5932334.988",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 53
    },
    {
        "id": "H11814",
        "easting": "575555.024",
        "northing": "5932454.05",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 65
    },
    {
        "id": "H10726",
        "easting": "571480.02",
        "northing": "5929066.455",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 223
    },
    {
        "id": "H13069",
        "easting": "571117.741",
        "northing": "5931375.554",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H13089",
        "easting": "571596.231",
        "northing": "5931377.246",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 4
    },
    {
        "id": "H11035",
        "easting": "571267.796",
        "northing": "5929748.94",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 149
    },
    {
        "id": "H10932",
        "easting": "572565.59",
        "northing": "5927404.789",
        "plz": 22113,
        "ortsteil": "Allermöhe",
        "str": "Moorfleeter Deich",
        "nr": 395
    },
    {
        "id": "H11031",
        "easting": "571283.758",
        "northing": "5929649.182",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 163
    },
    {
        "id": "H2912",
        "easting": "574488.94",
        "northing": "5930438.092",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 102
    },
    {
        "id": "H10934",
        "easting": "572556.366",
        "northing": "5927530.817",
        "plz": 22113,
        "ortsteil": "Allermöhe",
        "str": "Moorfleeter Deich",
        "nr": 385
    },
    {
        "id": "H14228",
        "easting": "574896.416",
        "northing": "5932437.733",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 51
    },
    {
        "id": "H2908",
        "easting": "574790.987",
        "northing": "5930071.578",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 140
    },
    {
        "id": "H12243",
        "easting": "571845.569",
        "northing": "5930104.94",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 43
    },
    {
        "id": "H13414",
        "easting": "571275.573",
        "northing": "5932426.366",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 2
    },
    {
        "id": "H11054",
        "easting": "570778.857",
        "northing": "5930943.705",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 25
    },
    {
        "id": "H10940",
        "easting": "572384.293",
        "northing": "5928006.03",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 344
    },
    {
        "id": "H10941",
        "easting": "572306.871",
        "northing": "5928091.413",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 339
    },
    {
        "id": "H10942",
        "easting": "572216.873",
        "northing": "5928158.423",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 316
    },
    {
        "id": "H10947",
        "easting": "572123.839",
        "northing": "5928230.901",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 317
    },
    {
        "id": "H11087",
        "easting": "571542.408",
        "northing": "5928472.446",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Tatenberger Weg",
        "nr": 12
    },
    {
        "id": "H11084",
        "easting": "571612.182",
        "northing": "5928572.762",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Tatenberger Weg",
        "nr": 1
    },
    {
        "id": "H11085",
        "easting": "571558.412",
        "northing": "5928484.071",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Tatenberger Weg",
        "nr": 12
    },
    {
        "id": "H11092",
        "easting": "571523.122",
        "northing": "5928351.178",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Tatenberger Weg",
        "nr": 23
    },
    {
        "id": "H11015",
        "easting": "571639.67",
        "northing": "5929619.351",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Kirchenweg",
        "nr": 52
    },
    {
        "id": "H11017",
        "easting": "571727.438",
        "northing": "5929710.82",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Kirchenweg",
        "nr": 61
    },
    {
        "id": "H13598",
        "easting": "572531.473",
        "northing": "5928404.981",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 158
    },
    {
        "id": "H11080",
        "easting": "571153.914",
        "northing": "5929001.584",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Vorlandring",
        "nr": 16
    },
    {
        "id": "H11037",
        "easting": "571241.52",
        "northing": "5930114.347",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 1
    },
    {
        "id": "H15785",
        "easting": "571382.912",
        "northing": "5931725.7",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 53
    },
    {
        "id": "H12203",
        "easting": "571764.705",
        "northing": "5930027.419",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Hein-Baxmann-Stieg",
        "nr": 27
    },
    {
        "id": "H11028",
        "easting": "571169.9",
        "northing": "5929593.657",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Holzhafenufer",
        "nr": 2
    },
    {
        "id": "H11060",
        "easting": "571330.05",
        "northing": "5930023.932",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 15
    },
    {
        "id": "H11063",
        "easting": "571493.889",
        "northing": "5929945.334",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 26
    },
    {
        "id": "H11067",
        "easting": "571614.042",
        "northing": "5929932.778",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 39
    },
    {
        "id": "H11069",
        "easting": "571827.252",
        "northing": "5929883.118",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 51
    },
    {
        "id": "H14258",
        "easting": "573965.346",
        "northing": "5932286.872",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Mühlenweg",
        "nr": 15
    },
    {
        "id": "H11012",
        "easting": "571480.549",
        "northing": "5929480.663",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Kirchenweg",
        "nr": 19
    },
    {
        "id": "H10951",
        "easting": "571886.384",
        "northing": "5928460.063",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 282
    },
    {
        "id": "H11041",
        "easting": "571187.348",
        "northing": "5930152.982",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 110
    },
    {
        "id": "H11049",
        "easting": "570880.371",
        "northing": "5930603.568",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 59
    },
    {
        "id": "H19490",
        "easting": "571658.741",
        "northing": "5932675.436",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 34
    },
    {
        "id": "H10723",
        "easting": "571529.369",
        "northing": "5928885.532",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 239
    },
    {
        "id": "H10721",
        "easting": "571589.903",
        "northing": "5928685.805",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 252
    },
    {
        "id": "H13166",
        "easting": "570853.121",
        "northing": "5931555.618",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.117 Tiefstack-Süd",
        "nr": 94
    },
    {
        "id": "H13156",
        "easting": "570889.83",
        "northing": "5931281.043",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H11044",
        "easting": "571016.336",
        "northing": "5930219.67",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 91
    },
    {
        "id": "H11045",
        "easting": "570956.757",
        "northing": "5930305.36",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 83
    },
    {
        "id": "H11048",
        "easting": "570911.076",
        "northing": "5930502.652",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 63
    },
    {
        "id": "H10731",
        "easting": "571340.241",
        "northing": "5928600.855",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Kneidenweg",
        "nr": 25
    },
    {
        "id": "H10732",
        "easting": "571251.796",
        "northing": "5928607.154",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Kneidenweg",
        "nr": 33
    },
    {
        "id": "H10733",
        "easting": "571164.15",
        "northing": "5928619.729",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Kneidenweg",
        "nr": 43
    },
    {
        "id": "H13164",
        "easting": "570857.599",
        "northing": "5931549.6",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.117 Tiefstack-Süd",
        "nr": 94
    },
    {
        "id": "H13168",
        "easting": "570889.933",
        "northing": "5931395.803",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.117 Tiefstack-Süd",
        "nr": 2
    },
    {
        "id": "H10965",
        "easting": "571671.919",
        "northing": "5928590.073",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 261
    },
    {
        "id": "H10734",
        "easting": "571139.529",
        "northing": "5928521.362",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 42
    },
    {
        "id": "H13600",
        "easting": "572373.376",
        "northing": "5928694.428",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 12
    },
    {
        "id": "H13646",
        "easting": "572328.158",
        "northing": "5928970.803",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 6
    },
    {
        "id": "H13647",
        "easting": "572313.572",
        "northing": "5928859.43",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 0
    },
    {
        "id": "H11050",
        "easting": "570816.49",
        "northing": "5930698.157",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 47
    },
    {
        "id": "H13597",
        "easting": "572588.011",
        "northing": "5928311.632",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 0
    },
    {
        "id": "H11052",
        "easting": "570778.389",
        "northing": "5930751.367",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 41
    },
    {
        "id": "H13648",
        "easting": "572126.391",
        "northing": "5928805.029",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 12
    },
    {
        "id": "H11036",
        "easting": "571218.599",
        "northing": "5930081.45",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 121
    },
    {
        "id": "H18434",
        "easting": "572569.796",
        "northing": "5932853.998",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Kolumbusstraße",
        "nr": 16
    },
    {
        "id": "H7585",
        "easting": "572521.168",
        "northing": "5931033.759",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 3
    },
    {
        "id": "H22104",
        "easting": "571502.599",
        "northing": "5931815.556",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 3
    },
    {
        "id": "H14297",
        "easting": "574919.965",
        "northing": "5932980.421",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Dudenweg",
        "nr": 16
    },
    {
        "id": "H16318",
        "easting": "573338.103",
        "northing": "5932677.152",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 86
    },
    {
        "id": "H16315",
        "easting": "573411.05",
        "northing": "5932621.362",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 98
    },
    {
        "id": "H14223",
        "easting": "574693.121",
        "northing": "5932488.81",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 59
    },
    {
        "id": "H14291",
        "easting": "574467.917",
        "northing": "5932464.248",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Druckerstraße",
        "nr": 47
    },
    {
        "id": "H16482",
        "easting": "574722.86",
        "northing": "5933096.234",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 1
    },
    {
        "id": "H8978",
        "easting": "575244.86",
        "northing": "5931579.357",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 123
    },
    {
        "id": "H16383",
        "easting": "573587.709",
        "northing": "5932790.655",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 55
    },
    {
        "id": "H1001",
        "easting": "573741.779",
        "northing": "5933101.734",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hollestraße",
        "nr": 15
    },
    {
        "id": "H9522",
        "easting": "576233.877",
        "northing": "5933117.94",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 126
    },
    {
        "id": "H9445",
        "easting": "576162.992",
        "northing": "5933107.186",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 122
    },
    {
        "id": "H9526",
        "easting": "576353.368",
        "northing": "5933255.492",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glinder Straße",
        "nr": 0
    },
    {
        "id": "H16246",
        "easting": "573817.896",
        "northing": "5933304.047",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Liebezeitstraße",
        "nr": 37
    },
    {
        "id": "H14237",
        "easting": "574329.325",
        "northing": "5932219.983",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mehrenskamp",
        "nr": 5
    },
    {
        "id": "H14296",
        "easting": "574465.657",
        "northing": "5932309.812",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Teubnerweg",
        "nr": 13
    },
    {
        "id": "H16519",
        "easting": "574656.175",
        "northing": "5933717.431",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mattkamp",
        "nr": 13
    },
    {
        "id": "H16561",
        "easting": "574833.638",
        "northing": "5933187.988",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 11
    },
    {
        "id": "H16558",
        "easting": "574728.536",
        "northing": "5933276.463",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Baxmannweg",
        "nr": 2
    },
    {
        "id": "H16556",
        "easting": "574715.006",
        "northing": "5933364.879",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 44
    },
    {
        "id": "H16575",
        "easting": "574829.906",
        "northing": "5933561.179",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 14
    },
    {
        "id": "H9534",
        "easting": "576099.651",
        "northing": "5933395.56",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glinder Straße",
        "nr": 1
    },
    {
        "id": "H11448",
        "easting": "575982.044",
        "northing": "5934228.27",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 0
    },
    {
        "id": "H14292",
        "easting": "574457.371",
        "northing": "5932395.037",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Teubnerweg",
        "nr": 22
    },
    {
        "id": "H14293",
        "easting": "574474.053",
        "northing": "5932340.509",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Teubnerweg",
        "nr": 10
    },
    {
        "id": "H15850",
        "easting": "574166.568",
        "northing": "5932713.892",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 102
    },
    {
        "id": "H7513",
        "easting": "572684.854",
        "northing": "5932089.153",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 27
    },
    {
        "id": "H7418",
        "easting": "572848.974",
        "northing": "5932225.621",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 59
    },
    {
        "id": "H15847",
        "easting": "574256.449",
        "northing": "5932729.093",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Letternkamp",
        "nr": 2
    },
    {
        "id": "H16299",
        "easting": "573543.33",
        "northing": "5932542.591",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Spökelbargring",
        "nr": 2
    },
    {
        "id": "H7416",
        "easting": "572844.876",
        "northing": "5932208.958",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 59
    },
    {
        "id": "H11446",
        "easting": "575933.611",
        "northing": "5933967.921",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 36
    },
    {
        "id": "H11451",
        "easting": "576036.342",
        "northing": "5934518.98",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "KLGV.119  Aufbau",
        "nr": 0
    },
    {
        "id": "H19531",
        "easting": "573104.432",
        "northing": "5932153.947",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 85
    },
    {
        "id": "H16970",
        "easting": "573880.569",
        "northing": "5932490.936",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "An der Schleemer Mühle",
        "nr": 11
    },
    {
        "id": "H16966",
        "easting": "573865.997",
        "northing": "5932401.288",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Klinkstraße",
        "nr": 14
    },
    {
        "id": "H14194",
        "easting": "574809.367",
        "northing": "5932171.173",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 30
    },
    {
        "id": "H14198",
        "easting": "574770.237",
        "northing": "5932069.899",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 17
    },
    {
        "id": "H14282",
        "easting": "574093.209",
        "northing": "5932518.285",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oberschleems",
        "nr": 21
    },
    {
        "id": "H14254",
        "easting": "574093.662",
        "northing": "5932352.116",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Klinkstraße",
        "nr": 19
    },
    {
        "id": "H14278",
        "easting": "574039.293",
        "northing": "5932365.373",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oberschleems",
        "nr": 38
    },
    {
        "id": "H14256",
        "easting": "574067.054",
        "northing": "5932263.659",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barckhusendamm",
        "nr": 24
    },
    {
        "id": "H14270",
        "easting": "573925.361",
        "northing": "5932394.022",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Klinkstraße",
        "nr": 20
    },
    {
        "id": "H14253",
        "easting": "574121.749",
        "northing": "5932444.371",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barckhusendamm",
        "nr": 2
    },
    {
        "id": "H16964",
        "easting": "573866.052",
        "northing": "5932105.368",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Billstedter Bahnstieg",
        "nr": 5
    },
    {
        "id": "H6278",
        "easting": "573854.521",
        "northing": "5933152.395",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 50
    },
    {
        "id": "H14193",
        "easting": "574846.269",
        "northing": "5932255.193",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 36
    },
    {
        "id": "H16225",
        "easting": "574108.926",
        "northing": "5933269.412",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Liebezeitstraße",
        "nr": 1
    },
    {
        "id": "H11007",
        "easting": "574897.84",
        "northing": "5933649.502",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 15
    },
    {
        "id": "H16457",
        "easting": "574148.716",
        "northing": "5933262.273",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 54
    },
    {
        "id": "H16229",
        "easting": "574195.155",
        "northing": "5933336.59",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 55
    },
    {
        "id": "H9036",
        "easting": "575844.863",
        "northing": "5932075.313",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Edvard-Munch-Straße",
        "nr": 30
    },
    {
        "id": "H16475",
        "easting": "574479.09",
        "northing": "5933124.099",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Luisenhofstieg",
        "nr": 14
    },
    {
        "id": "H16550",
        "easting": "574677.348",
        "northing": "5933461.812",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 87
    },
    {
        "id": "H16545",
        "easting": "574558.369",
        "northing": "5933447.439",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 79
    },
    {
        "id": "H16230",
        "easting": "574268.94",
        "northing": "5933377.689",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 58
    },
    {
        "id": "H16479",
        "easting": "574610.291",
        "northing": "5933127.154",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Cottaweg",
        "nr": 52
    },
    {
        "id": "H11452",
        "easting": "576069.397",
        "northing": "5934692.053",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 0
    },
    {
        "id": "H16522",
        "easting": "574772.65",
        "northing": "5933546.447",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 5
    },
    {
        "id": "H11347",
        "easting": "573613.359",
        "northing": "5933214.361",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 4
    },
    {
        "id": "H16295",
        "easting": "573575.717",
        "northing": "5932576.83",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Spökelbargring",
        "nr": 3
    },
    {
        "id": "H16377",
        "easting": "573793.693",
        "northing": "5932684.097",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 75
    },
    {
        "id": "H19516",
        "easting": "573256.765",
        "northing": "5932313.911",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 210
    },
    {
        "id": "H19521",
        "easting": "573520.359",
        "northing": "5932230.749",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 229
    },
    {
        "id": "H14288",
        "easting": "574270.469",
        "northing": "5932478.154",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Druckerstraße",
        "nr": 20
    },
    {
        "id": "H14287",
        "easting": "574192.99",
        "northing": "5932527.828",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Druckerstraße",
        "nr": 8
    },
    {
        "id": "H14224",
        "easting": "574707.083",
        "northing": "5932429.276",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 53
    },
    {
        "id": "H16454",
        "easting": "574094.789",
        "northing": "5933177.51",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 48
    },
    {
        "id": "H16375",
        "easting": "573720.806",
        "northing": "5932708.647",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 60
    },
    {
        "id": "H16341",
        "easting": "573742.837",
        "northing": "5932716.354",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 71
    },
    {
        "id": "H16339",
        "easting": "573845.369",
        "northing": "5932686.956",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 81
    },
    {
        "id": "H6792",
        "easting": "573806.509",
        "northing": "5932929.069",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 49
    },
    {
        "id": "H6780",
        "easting": "573769.796",
        "northing": "5932751.592",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 10
    },
    {
        "id": "H7918",
        "easting": "572903.559",
        "northing": "5932829.8",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Moorfleeter Straße",
        "nr": 1
    },
    {
        "id": "H11306",
        "easting": "573821.699",
        "northing": "5933046.095",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 38
    },
    {
        "id": "H16462",
        "easting": "574633.73",
        "northing": "5933550.967",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mattkamp",
        "nr": 3
    },
    {
        "id": "H16292",
        "easting": "573656.593",
        "northing": "5932633.972",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Spökelbarg",
        "nr": 15
    },
    {
        "id": "H18731",
        "easting": "572664.752",
        "northing": "5932108.646",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 28
    },
    {
        "id": "H11747",
        "easting": "575422.7",
        "northing": "5932700.795",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 42
    },
    {
        "id": "H11735",
        "easting": "575633.401",
        "northing": "5932816.203",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archsumer Weg",
        "nr": 2
    },
    {
        "id": "H11731",
        "easting": "575719.429",
        "northing": "5932863.597",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 66
    },
    {
        "id": "H15819",
        "easting": "574626.628",
        "northing": "5932633.64",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 76
    },
    {
        "id": "H15815",
        "easting": "574464.826",
        "northing": "5932571.825",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Letternkamp",
        "nr": 35
    },
    {
        "id": "H15813",
        "easting": "574446.811",
        "northing": "5932624.266",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Letternkamp",
        "nr": 25
    },
    {
        "id": "H17733",
        "easting": "574549.28",
        "northing": "5931911.247",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 69
    },
    {
        "id": "H17734",
        "easting": "574455.89",
        "northing": "5931944.636",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 59
    },
    {
        "id": "H17271",
        "easting": "574276.007",
        "northing": "5932022.518",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Weg",
        "nr": 23
    },
    {
        "id": "H17963",
        "easting": "574186.556",
        "northing": "5932050.959",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 31
    },
    {
        "id": "H17973",
        "easting": "574505.188",
        "northing": "5932044.212",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lohkamp",
        "nr": 11
    },
    {
        "id": "H17972",
        "easting": "574583.184",
        "northing": "5931944.807",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 3
    },
    {
        "id": "H17360",
        "easting": "573966.792",
        "northing": "5931906.886",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Rotenbrückenweg",
        "nr": 20
    },
    {
        "id": "H17362",
        "easting": "574026.128",
        "northing": "5931833.744",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Rotenbrückenweg",
        "nr": 32
    },
    {
        "id": "H13633",
        "easting": "573972.328",
        "northing": "5932045.065",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Billstedter Bahnstieg",
        "nr": 12
    },
    {
        "id": "H11763",
        "easting": "575472.723",
        "northing": "5932862.105",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 32
    },
    {
        "id": "H16962",
        "easting": "573742.941",
        "northing": "5932381.494",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 121
    },
    {
        "id": "H17236",
        "easting": "574834.029",
        "northing": "5932946.353",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Dudenweg",
        "nr": 8
    },
    {
        "id": "H14335",
        "easting": "574122.349",
        "northing": "5932819.416",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaspar-Siemers-Stieg",
        "nr": 17
    },
    {
        "id": "H16487",
        "easting": "574718.799",
        "northing": "5933000.082",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 14
    },
    {
        "id": "H14299",
        "easting": "574814.366",
        "northing": "5932829.818",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 163
    },
    {
        "id": "H15835",
        "easting": "574830.529",
        "northing": "5932811.316",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 154
    },
    {
        "id": "H14307",
        "easting": "574563.232",
        "northing": "5932792.866",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 141
    },
    {
        "id": "H14310",
        "easting": "574466.336",
        "northing": "5932778.635",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 131
    },
    {
        "id": "H15842",
        "easting": "574468.936",
        "northing": "5932756.573",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 128
    },
    {
        "id": "H16312",
        "easting": "573585.04",
        "northing": "5932492.109",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 114
    },
    {
        "id": "H19519",
        "easting": "573380.465",
        "northing": "5932274.733",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 220
    },
    {
        "id": "H19530",
        "easting": "573233.209",
        "northing": "5932117.937",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 89
    },
    {
        "id": "H16483",
        "easting": "574862.571",
        "northing": "5933062.298",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Tabulatorweg",
        "nr": 16
    },
    {
        "id": "H15829",
        "easting": "574707.923",
        "northing": "5932783.827",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 144
    },
    {
        "id": "H14248",
        "easting": "574140.814",
        "northing": "5932288.078",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schöfferstieg",
        "nr": 16
    },
    {
        "id": "H14265",
        "easting": "574014.619",
        "northing": "5932120.687",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 11
    },
    {
        "id": "H14266",
        "easting": "574095.295",
        "northing": "5932083.739",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 19
    },
    {
        "id": "H9547",
        "easting": "575889.344",
        "northing": "5932850.033",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Dagebüller Weg",
        "nr": 8
    },
    {
        "id": "H11792",
        "easting": "575347.108",
        "northing": "5932658.373",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 34
    },
    {
        "id": "H10867",
        "easting": "575480.589",
        "northing": "5933654.694",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 31
    },
    {
        "id": "H10876",
        "easting": "575623.656",
        "northing": "5933572.443",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 33
    },
    {
        "id": "H11683",
        "easting": "575510.387",
        "northing": "5933565.113",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 41
    },
    {
        "id": "H14338",
        "easting": "574230.621",
        "northing": "5932833.026",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Papyrusweg",
        "nr": 9
    },
    {
        "id": "H14339",
        "easting": "574334.234",
        "northing": "5932836.989",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Papyrusweg",
        "nr": 17
    },
    {
        "id": "H16466",
        "easting": "574508.046",
        "northing": "5932832.282",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 137
    },
    {
        "id": "H14334",
        "easting": "574144.28",
        "northing": "5932861.559",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemkoppel",
        "nr": 5
    },
    {
        "id": "H9563",
        "easting": "575929.291",
        "northing": "5932948.819",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 2
    },
    {
        "id": "H352",
        "easting": "575983.271",
        "northing": "5932675.133",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Niebüllweg",
        "nr": 19
    },
    {
        "id": "H9537",
        "easting": "576104.958",
        "northing": "5932644.676",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 17
    },
    {
        "id": "H9543",
        "easting": "576043.78",
        "northing": "5932627.131",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 99
    },
    {
        "id": "H14337",
        "easting": "574110.032",
        "northing": "5932755.17",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemkoppel",
        "nr": 1
    },
    {
        "id": "H21268",
        "easting": "573334.666",
        "northing": "5931961.366",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 105
    },
    {
        "id": "H10885",
        "easting": "575889.711",
        "northing": "5933735.687",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 28
    },
    {
        "id": "H10883",
        "easting": "575884.013",
        "northing": "5933704.97",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 28
    },
    {
        "id": "H10833",
        "easting": "575679.165",
        "northing": "5933664.615",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 32
    },
    {
        "id": "H10843",
        "easting": "575405.014",
        "northing": "5933710.498",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbargenredder",
        "nr": 23
    },
    {
        "id": "H7760",
        "easting": "576438.013",
        "northing": "5931129.2",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Heideblöck",
        "nr": 23
    },
    {
        "id": "H11000",
        "easting": "574936.824",
        "northing": "5933369.466",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kampmoortwiete",
        "nr": 12
    },
    {
        "id": "H10340",
        "easting": "576113.031",
        "northing": "5932815.874",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kampener Stieg",
        "nr": 9
    },
    {
        "id": "H8903",
        "easting": "576149.041",
        "northing": "5932955.014",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Keitumer Weg",
        "nr": 16
    },
    {
        "id": "H10887",
        "easting": "575838.362",
        "northing": "5933510.312",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 30
    },
    {
        "id": "H16493",
        "easting": "574647.791",
        "northing": "5932936.879",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Cottaweg",
        "nr": 6
    },
    {
        "id": "H16494",
        "easting": "574560.8",
        "northing": "5932946.937",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Cottaweg",
        "nr": 12
    },
    {
        "id": "H15843",
        "easting": "574349.438",
        "northing": "5932740.934",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 118
    },
    {
        "id": "H14315",
        "easting": "574177.603",
        "northing": "5932733.005",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 107
    },
    {
        "id": "H8948",
        "easting": "576069.09",
        "northing": "5931133.811",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 195
    },
    {
        "id": "H8954",
        "easting": "575942.879",
        "northing": "5931087.632",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Wilhelm-Lehmbruck-Straße",
        "nr": 2
    },
    {
        "id": "H8797",
        "easting": "575946.651",
        "northing": "5931173.407",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Wilhelm-Lehmbruck-Straße",
        "nr": 10
    },
    {
        "id": "H8298",
        "easting": "576018.05",
        "northing": "5931300.884",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 31
    },
    {
        "id": "H8922",
        "easting": "576050.149",
        "northing": "5931547.482",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 44
    },
    {
        "id": "H17968",
        "easting": "574702.682",
        "northing": "5931899.583",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Markt",
        "nr": 9
    },
    {
        "id": "H8282",
        "easting": "576279.982",
        "northing": "5931104.32",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 4
    },
    {
        "id": "H7727",
        "easting": "576486.006",
        "northing": "5931067.737",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 28
    },
    {
        "id": "H7773",
        "easting": "576480.698",
        "northing": "5931075.613",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 52
    },
    {
        "id": "H8997",
        "easting": "576350.934",
        "northing": "5931135.586",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 8
    },
    {
        "id": "H17997",
        "easting": "574922.255",
        "northing": "5931815.784",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 108
    },
    {
        "id": "H18005",
        "easting": "575025.39",
        "northing": "5931738.114",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 120
    },
    {
        "id": "H17983",
        "easting": "574949.079",
        "northing": "5931971.076",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 117
    },
    {
        "id": "H14321",
        "easting": "573994.941",
        "northing": "5932701.294",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 89
    },
    {
        "id": "H15856",
        "easting": "573916.367",
        "northing": "5932689.457",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 1
    },
    {
        "id": "H11744",
        "easting": "575678.423",
        "northing": "5932759.003",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archsumer Weg",
        "nr": 18
    },
    {
        "id": "H11743",
        "easting": "575691.679",
        "northing": "5932723.145",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archsumer Weg",
        "nr": 16
    },
    {
        "id": "H11788",
        "easting": "575017.092",
        "northing": "5932870.164",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 172
    },
    {
        "id": "H16492",
        "easting": "574714.8",
        "northing": "5932908.618",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 6
    },
    {
        "id": "H15832",
        "easting": "574882.566",
        "northing": "5932786.795",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Amrumer Knick",
        "nr": 17
    },
    {
        "id": "H10916",
        "easting": "575475.989",
        "northing": "5933160.624",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 227
    },
    {
        "id": "H11725",
        "easting": "575805.296",
        "northing": "5932926.828",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 81
    },
    {
        "id": "H5462",
        "easting": "575223.445",
        "northing": "5932885.254",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Wegkoppel",
        "nr": 9
    },
    {
        "id": "H5464",
        "easting": "575263.768",
        "northing": "5932809.573",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Wegkoppel",
        "nr": 21
    },
    {
        "id": "H5465",
        "easting": "575307.491",
        "northing": "5932728.195",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Wegkoppel",
        "nr": 35
    },
    {
        "id": "H9566",
        "easting": "575931.62",
        "northing": "5932965.064",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 1
    },
    {
        "id": "H15839",
        "easting": "574614.358",
        "northing": "5932780.52",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 140
    },
    {
        "id": "H15836",
        "easting": "574752.522",
        "northing": "5932800.1",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 150
    },
    {
        "id": "H15801",
        "easting": "574009.833",
        "northing": "5932566.191",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 12
    },
    {
        "id": "H14327",
        "easting": "574248.145",
        "northing": "5933008.59",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Pergamentweg",
        "nr": 8
    },
    {
        "id": "H16472",
        "easting": "574381.632",
        "northing": "5933024.196",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Pergamentweg",
        "nr": 36
    },
    {
        "id": "H14322",
        "easting": "573973.583",
        "northing": "5932777.226",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaspar-Siemers-Weg",
        "nr": 4
    },
    {
        "id": "H10899",
        "easting": "575808.31",
        "northing": "5933410.955",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Moosterhoop",
        "nr": 9
    },
    {
        "id": "H14408",
        "easting": "573032.926",
        "northing": "5931619.647",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 83
    },
    {
        "id": "H12430",
        "easting": "572320.457",
        "northing": "5930041.871",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H19375",
        "easting": "573750.132",
        "northing": "5931634.36",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 317
    },
    {
        "id": "H11233",
        "easting": "573762.596",
        "northing": "5931720.982",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 309
    },
    {
        "id": "H11234",
        "easting": "573720.384",
        "northing": "5931820.321",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 301
    },
    {
        "id": "H17364",
        "easting": "573830.928",
        "northing": "5932061.533",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Rote Brücke",
        "nr": 6
    },
    {
        "id": "H21267",
        "easting": "573301.93",
        "northing": "5932052.401",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 91
    },
    {
        "id": "H11783",
        "easting": "575088.217",
        "northing": "5932926.804",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Steinkamp",
        "nr": 31
    },
    {
        "id": "H11801",
        "easting": "575016.47",
        "northing": "5932633.809",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Landjägerstieg",
        "nr": 18
    },
    {
        "id": "H11789",
        "easting": "574928.738",
        "northing": "5932854.1",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 175
    },
    {
        "id": "H11767",
        "easting": "575315.546",
        "northing": "5933034.709",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 207
    },
    {
        "id": "H9558",
        "easting": "576021.924",
        "northing": "5932783.855",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 14
    },
    {
        "id": "H10641",
        "easting": "576082.699",
        "northing": "5933062.986",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 112
    },
    {
        "id": "H10995",
        "easting": "575089.669",
        "northing": "5933214.002",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Heinrich-Schulte-Höhe",
        "nr": 1
    },
    {
        "id": "H11120",
        "easting": "575593.757",
        "northing": "5933553.16",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 35
    },
    {
        "id": "H11121",
        "easting": "575565.467",
        "northing": "5933558.038",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 37
    },
    {
        "id": "H10943",
        "easting": "575850.837",
        "northing": "5933175.664",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaltenbergen",
        "nr": 27
    },
    {
        "id": "H16298",
        "easting": "573646.763",
        "northing": "5932464.321",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 109
    },
    {
        "id": "H16307",
        "easting": "573682.807",
        "northing": "5932416.369",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 120
    },
    {
        "id": "H16279",
        "easting": "573696.92",
        "northing": "5932477.278",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schiffbeker Schanze",
        "nr": 7
    },
    {
        "id": "H16284",
        "easting": "573671.053",
        "northing": "5932543.774",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Spökelbargring",
        "nr": 11
    },
    {
        "id": "H10939",
        "easting": "575820.917",
        "northing": "5933250.294",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaltenbergen",
        "nr": 22
    },
    {
        "id": "H14323",
        "easting": "573994.207",
        "northing": "5932823.02",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaspar-Siemers-Weg",
        "nr": 6
    },
    {
        "id": "H14325",
        "easting": "574125.731",
        "northing": "5932938.432",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaspar-Siemers-Weg",
        "nr": 28
    },
    {
        "id": "H14324",
        "easting": "574024.675",
        "northing": "5932871.811",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaspar-Siemers-Stieg",
        "nr": 10
    },
    {
        "id": "H16289",
        "easting": "573609.391",
        "northing": "5932541.023",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Spökelbarg",
        "nr": 3
    },
    {
        "id": "H16274",
        "easting": "573752.585",
        "northing": "5932512.507",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 7
    },
    {
        "id": "H9561",
        "easting": "575974.713",
        "northing": "5932870.226",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 4
    },
    {
        "id": "H15827",
        "easting": "574802.355",
        "northing": "5932612.388",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 69
    },
    {
        "id": "H11806",
        "easting": "574956.805",
        "northing": "5932757.607",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Wittdüner Weg",
        "nr": 2
    },
    {
        "id": "H11799",
        "easting": "575182.589",
        "northing": "5932567.299",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 14
    },
    {
        "id": "H11809",
        "easting": "575422.022",
        "northing": "5932578.762",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 58
    },
    {
        "id": "H11810",
        "easting": "575477.852",
        "northing": "5932573.132",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 79
    },
    {
        "id": "H10923",
        "easting": "575649.472",
        "northing": "5933429.915",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 3
    },
    {
        "id": "H10924",
        "easting": "575660.711",
        "northing": "5933523.404",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 9
    },
    {
        "id": "H10966",
        "easting": "575414.207",
        "northing": "5933534.353",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kantatenweg",
        "nr": 6
    },
    {
        "id": "H1002",
        "easting": "573653.058",
        "northing": "5933129.263",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hollestraße",
        "nr": 3
    },
    {
        "id": "H13523",
        "easting": "572905.317",
        "northing": "5930506.625",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 12
    },
    {
        "id": "H16520",
        "easting": "574657.656",
        "northing": "5933827.767",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mattkamp",
        "nr": 10
    },
    {
        "id": "H15823",
        "easting": "574388.954",
        "northing": "5932657.435",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Setzergasse",
        "nr": 13
    },
    {
        "id": "H15825",
        "easting": "574425.706",
        "northing": "5932730.125",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Setzergasse",
        "nr": 2
    },
    {
        "id": "H10920",
        "easting": "575664.963",
        "northing": "5933343.623",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 261
    },
    {
        "id": "H18462",
        "easting": "575263.478",
        "northing": "5933628.038",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Rodeweg",
        "nr": 19
    },
    {
        "id": "H11118",
        "easting": "575622.516",
        "northing": "5933545.574",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 33
    },
    {
        "id": "H11776",
        "easting": "575344.39",
        "northing": "5932849.729",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schümannweg",
        "nr": 31
    },
    {
        "id": "H14262",
        "easting": "573917.848",
        "northing": "5932198.754",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Mühlenweg",
        "nr": 1
    },
    {
        "id": "H14275",
        "easting": "573901.031",
        "northing": "5932556.623",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "An der Schleemer Mühle",
        "nr": 19
    },
    {
        "id": "H14276",
        "easting": "573936.124",
        "northing": "5932517.888",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Ring",
        "nr": 8
    },
    {
        "id": "H8768",
        "easting": "575750.224",
        "northing": "5931335.501",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 61
    },
    {
        "id": "H13172",
        "easting": "570865.949",
        "northing": "5931284.722",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 8
    },
    {
        "id": "H9022",
        "easting": "575585.909",
        "northing": "5932175.309",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 77
    },
    {
        "id": "H9023",
        "easting": "575636.001",
        "northing": "5932189.804",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 85
    },
    {
        "id": "H12255",
        "easting": "571534.274",
        "northing": "5930422.876",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 35
    },
    {
        "id": "H8299",
        "easting": "575888.732",
        "northing": "5931301.376",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 56
    },
    {
        "id": "H8301",
        "easting": "575785.069",
        "northing": "5931301.205",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 42
    },
    {
        "id": "H8789",
        "easting": "575685.378",
        "northing": "5931310.758",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 36
    },
    {
        "id": "H15762",
        "easting": "572458.161",
        "northing": "5929980.674",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Feldhofe",
        "nr": 0
    },
    {
        "id": "H12226",
        "easting": "572062.377",
        "northing": "5929885.05",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 51
    },
    {
        "id": "H8766",
        "easting": "575686.678",
        "northing": "5931331.031",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 46
    },
    {
        "id": "H8771",
        "easting": "575748.897",
        "northing": "5931370.848",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 49
    },
    {
        "id": "H8773",
        "easting": "575749.019",
        "northing": "5931401.277",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 39
    },
    {
        "id": "H8775",
        "easting": "575658.313",
        "northing": "5931405.422",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 36
    },
    {
        "id": "H8780",
        "easting": "575741.945",
        "northing": "5931466.923",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 19
    },
    {
        "id": "H8782",
        "easting": "575657.837",
        "northing": "5931473.457",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 18
    },
    {
        "id": "H7776",
        "easting": "576315.794",
        "northing": "5931339.662",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Bienenbusch",
        "nr": 5
    },
    {
        "id": "H7768",
        "easting": "576561.019",
        "northing": "5931379.976",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 23
    },
    {
        "id": "H7769",
        "easting": "576560.727",
        "northing": "5931280.906",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 33
    },
    {
        "id": "H7772",
        "easting": "576560.129",
        "northing": "5931078.833",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 61
    },
    {
        "id": "H7780",
        "easting": "576284.588",
        "northing": "5931397.791",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 12
    },
    {
        "id": "H7793",
        "easting": "576376.495",
        "northing": "5931417.562",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 8
    },
    {
        "id": "H7792",
        "easting": "576376.709",
        "northing": "5931510.895",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Lietbargredder",
        "nr": 11
    },
    {
        "id": "H8801",
        "easting": "576112.772",
        "northing": "5931422.447",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Paul-Klee-Straße",
        "nr": 6
    },
    {
        "id": "H11753",
        "easting": "575666.702",
        "northing": "5932996.111",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Tinnumer Weg",
        "nr": 2
    },
    {
        "id": "H8253",
        "easting": "576497.749",
        "northing": "5932190.73",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Am Mühlenbach",
        "nr": 18
    },
    {
        "id": "H8256",
        "easting": "576497.607",
        "northing": "5932094.613",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Am Mühlenbach",
        "nr": 6
    },
    {
        "id": "H8787",
        "easting": "575704.283",
        "northing": "5931542.194",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 26
    },
    {
        "id": "H8784",
        "easting": "575740.638",
        "northing": "5931502.77",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 9
    },
    {
        "id": "H8273",
        "easting": "576609.532",
        "northing": "5930672.45",
        "plz": 22115,
        "ortsteil": "Lohbrügge",
        "str": "Asbrookdamm",
        "nr": 11
    },
    {
        "id": "H10975",
        "easting": "575389.67",
        "northing": "5933352.003",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 66
    },
    {
        "id": "H10977",
        "easting": "575278.525",
        "northing": "5933316.775",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 56
    },
    {
        "id": "H10853",
        "easting": "575044.121",
        "northing": "5933665.762",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 10
    },
    {
        "id": "H10856",
        "easting": "575117.274",
        "northing": "5933663.773",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 17
    },
    {
        "id": "H10857",
        "easting": "575031.175",
        "northing": "5933628.718",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 20
    },
    {
        "id": "H8790",
        "easting": "575598.855",
        "northing": "5931393.944",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 22
    },
    {
        "id": "H8792",
        "easting": "575578.664",
        "northing": "5931421.783",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 7
    },
    {
        "id": "H8794",
        "easting": "575510.106",
        "northing": "5931483.619",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 10
    },
    {
        "id": "H8973",
        "easting": "575280.841",
        "northing": "5931523.524",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 154
    },
    {
        "id": "H8958",
        "easting": "575675.995",
        "northing": "5931225.223",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 167
    },
    {
        "id": "H8957",
        "easting": "575737.744",
        "northing": "5931190.609",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 171
    },
    {
        "id": "H12800",
        "easting": "572065.804",
        "northing": "5931469.617",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 8
    },
    {
        "id": "H14410",
        "easting": "572934.405",
        "northing": "5931648.648",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 77
    },
    {
        "id": "H7818",
        "easting": "576376.071",
        "northing": "5932128.477",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hollkoppelweg",
        "nr": 16
    },
    {
        "id": "H7819",
        "easting": "576432.521",
        "northing": "5932159.273",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hollkoppelweg",
        "nr": 29
    },
    {
        "id": "H9024",
        "easting": "575668.459",
        "northing": "5932209.846",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 87
    },
    {
        "id": "H9025",
        "easting": "575689.351",
        "northing": "5932147.932",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 103
    },
    {
        "id": "H9083",
        "easting": "575806.169",
        "northing": "5932288.085",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Pechstein-Straße",
        "nr": 47
    },
    {
        "id": "H9038",
        "easting": "575822.121",
        "northing": "5932106.52",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Edvard-Munch-Straße",
        "nr": 31
    },
    {
        "id": "H8970",
        "easting": "575308.977",
        "northing": "5931497.283",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 156
    },
    {
        "id": "H8975",
        "easting": "575372.757",
        "northing": "5931469.003",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 133
    },
    {
        "id": "H17979",
        "easting": "574922.199",
        "northing": "5932049.369",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 3
    },
    {
        "id": "H18867",
        "easting": "575210.539",
        "northing": "5931851.446",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 17
    },
    {
        "id": "H9013",
        "easting": "575663.129",
        "northing": "5932024.091",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 62
    },
    {
        "id": "H9004",
        "easting": "575487.044",
        "northing": "5932004.675",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 33
    },
    {
        "id": "H9002",
        "easting": "575569.571",
        "northing": "5931922.874",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 19
    },
    {
        "id": "H9011",
        "easting": "575642.736",
        "northing": "5931998.08",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 38
    },
    {
        "id": "H8812",
        "easting": "575871.712",
        "northing": "5931530.842",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 30
    },
    {
        "id": "H8279",
        "easting": "576757.544",
        "northing": "5930626.052",
        "plz": 22115,
        "ortsteil": "Lohbrügge",
        "str": "Asbrookdamm",
        "nr": 6
    },
    {
        "id": "H9034",
        "easting": "575874.166",
        "northing": "5931879.627",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Edvard-Munch-Straße",
        "nr": 5
    },
    {
        "id": "H8987",
        "easting": "575321.711",
        "northing": "5931723.688",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hummelweg",
        "nr": 1
    },
    {
        "id": "H8990",
        "easting": "575521.78",
        "northing": "5931732.517",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hummelweg",
        "nr": 17
    },
    {
        "id": "H8983",
        "easting": "575392.215",
        "northing": "5931602.068",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Haseneck",
        "nr": 2
    },
    {
        "id": "H8993",
        "easting": "575826.5",
        "northing": "5931669.949",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Klinger-Straße",
        "nr": 13
    },
    {
        "id": "H10711",
        "easting": "573529.15",
        "northing": "5931688.476",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 101
    },
    {
        "id": "H10714",
        "easting": "573563.267",
        "northing": "5931599.257",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 103
    },
    {
        "id": "H18427",
        "easting": "572294.269",
        "northing": "5933077.707",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H7605",
        "easting": "572752.407",
        "northing": "5931267.396",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 20
    },
    {
        "id": "H7614",
        "easting": "572550.584",
        "northing": "5931326.914",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 13
    },
    {
        "id": "H13085",
        "easting": "571902.394",
        "northing": "5931117.288",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 17
    },
    {
        "id": "H10979",
        "easting": "575310.924",
        "northing": "5933283.961",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 56
    },
    {
        "id": "H10980",
        "easting": "575186.387",
        "northing": "5933287.625",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 44
    },
    {
        "id": "H10992",
        "easting": "575016.54",
        "northing": "5933323.935",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glitzaweg",
        "nr": 9
    },
    {
        "id": "H9997",
        "easting": "576133.076",
        "northing": "5932711.732",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lister Weg",
        "nr": 6
    },
    {
        "id": "H10962",
        "easting": "575355.256",
        "northing": "5933552.812",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Bonhoefferstraße",
        "nr": 14
    },
    {
        "id": "H11822",
        "easting": "575389.154",
        "northing": "5932373.82",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 0
    },
    {
        "id": "H11823",
        "easting": "575300.597",
        "northing": "5932320.036",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 29
    },
    {
        "id": "H10996",
        "easting": "575136.857",
        "northing": "5933129.757",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Heinrich-Schulte-Höhe",
        "nr": 17
    },
    {
        "id": "H10964",
        "easting": "575358.893",
        "northing": "5933482.753",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Bonhoefferstraße",
        "nr": 12
    },
    {
        "id": "H10999",
        "easting": "574925.895",
        "northing": "5933292.038",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kampmoortwiete",
        "nr": 1
    },
    {
        "id": "H10960",
        "easting": "575573.434",
        "northing": "5933528.85",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Ohlwurt",
        "nr": 21
    },
    {
        "id": "H10922",
        "easting": "575555.457",
        "northing": "5933409.983",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Breedenweg",
        "nr": 7
    },
    {
        "id": "H9067",
        "easting": "576068.581",
        "northing": "5931805.659",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Oskar-Schlemmer-Straße",
        "nr": 1
    },
    {
        "id": "H7754",
        "easting": "576203.065",
        "northing": "5931830.812",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 10
    },
    {
        "id": "H8258",
        "easting": "576575.185",
        "northing": "5931034.921",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 48
    },
    {
        "id": "H8939",
        "easting": "575769.462",
        "northing": "5931564.35",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 26
    },
    {
        "id": "H11938",
        "easting": "572262.162",
        "northing": "5931413.068",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 12
    },
    {
        "id": "H12231",
        "easting": "572147.445",
        "northing": "5929796.476",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 61
    },
    {
        "id": "H8930",
        "easting": "575683.481",
        "northing": "5931544.082",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 26
    },
    {
        "id": "H8917",
        "easting": "575599.331",
        "northing": "5931533.093",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 16
    },
    {
        "id": "H8810",
        "easting": "575871.229",
        "northing": "5931318.14",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Münterweg",
        "nr": 21
    },
    {
        "id": "H17273",
        "easting": "574215.074",
        "northing": "5932031.606",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mehrenskamp",
        "nr": 28
    },
    {
        "id": "H17989",
        "easting": "574827.778",
        "northing": "5931816.341",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 102
    },
    {
        "id": "H17729",
        "easting": "574625.587",
        "northing": "5931847.818",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 77
    },
    {
        "id": "H7762",
        "easting": "576438.311",
        "northing": "5931230.317",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Heideblöck",
        "nr": 15
    },
    {
        "id": "H7763",
        "easting": "576438.5",
        "northing": "5931329.446",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Heideblöck",
        "nr": 5
    },
    {
        "id": "H11764",
        "easting": "575568.217",
        "northing": "5932677.782",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 52
    },
    {
        "id": "H11762",
        "easting": "575419.845",
        "northing": "5932959.409",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 16
    },
    {
        "id": "H11759",
        "easting": "575463.83",
        "northing": "5932992.308",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 7
    },
    {
        "id": "H10878",
        "easting": "575458.305",
        "northing": "5933618.409",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 29
    },
    {
        "id": "H10879",
        "easting": "575365.695",
        "northing": "5933622.964",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Rodeweg",
        "nr": 4
    },
    {
        "id": "H10953",
        "easting": "575460.49",
        "northing": "5933449.582",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 16
    },
    {
        "id": "H7786",
        "easting": "576314.535",
        "northing": "5931702.899",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kleine Holl",
        "nr": 23
    },
    {
        "id": "H8257",
        "easting": "576497.602",
        "northing": "5931995.298",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Am Mühlenbach",
        "nr": 2
    },
    {
        "id": "H7817",
        "easting": "576376.019",
        "northing": "5932043.227",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hollkoppelweg",
        "nr": 8
    },
    {
        "id": "H7815",
        "easting": "576436.518",
        "northing": "5931912.121",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Strietkoppel",
        "nr": 1
    },
    {
        "id": "H7746",
        "easting": "576207.985",
        "northing": "5931594.721",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 59
    },
    {
        "id": "H9061",
        "easting": "576144.124",
        "northing": "5931673.068",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Feiningerstraße",
        "nr": 1
    },
    {
        "id": "H9062",
        "easting": "576144.623",
        "northing": "5931793.519",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Feiningerstraße",
        "nr": 2
    },
    {
        "id": "H7797",
        "easting": "576557.924",
        "northing": "5931740.317",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 32
    },
    {
        "id": "H7812",
        "easting": "576558.141",
        "northing": "5931843.01",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 42
    },
    {
        "id": "H7811",
        "easting": "576558.247",
        "northing": "5931943.544",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 56
    },
    {
        "id": "H12269",
        "easting": "571293.6",
        "northing": "5930666.212",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 19
    },
    {
        "id": "H12260",
        "easting": "571372.612",
        "northing": "5930586.589",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 19
    },
    {
        "id": "H10881",
        "easting": "575262.448",
        "northing": "5933618.212",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Rodeweg",
        "nr": 19
    },
    {
        "id": "H18463",
        "easting": "575214.357",
        "northing": "5933653.677",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Rodeweg",
        "nr": 10
    },
    {
        "id": "H18430",
        "easting": "572379.354",
        "northing": "5933027.309",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H18432",
        "easting": "572459.711",
        "northing": "5932971.752",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Kolumbusstraße",
        "nr": 12
    },
    {
        "id": "H12251",
        "easting": "571636.695",
        "northing": "5930289.595",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 37
    },
    {
        "id": "H8969",
        "easting": "575372.387",
        "northing": "5931440.842",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 164
    },
    {
        "id": "H7725",
        "easting": "576624.507",
        "northing": "5931036.59",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 50
    },
    {
        "id": "H15878",
        "easting": "571500.761",
        "northing": "5931845.035",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 22
    },
    {
        "id": "H10845",
        "easting": "575068.356",
        "northing": "5933757.985",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 40
    },
    {
        "id": "H10844",
        "easting": "575092.647",
        "northing": "5933799.988",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 0
    },
    {
        "id": "H9049",
        "easting": "575698.14",
        "northing": "5931827.543",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 76
    },
    {
        "id": "H9085",
        "easting": "576021.92",
        "northing": "5931825.803",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 78
    },
    {
        "id": "H9088",
        "easting": "576090.095",
        "northing": "5931829.77",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 75
    },
    {
        "id": "H8943",
        "easting": "575991.851",
        "northing": "5931566.367",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 35
    },
    {
        "id": "H10660",
        "easting": "576070.313",
        "northing": "5931568.515",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 39
    },
    {
        "id": "H9072",
        "easting": "575947.436",
        "northing": "5931797.216",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 72
    },
    {
        "id": "H7723",
        "easting": "576646.449",
        "northing": "5931044.176",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 50
    },
    {
        "id": "H8263",
        "easting": "576453.351",
        "northing": "5930994.745",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 32
    },
    {
        "id": "H18872",
        "easting": "575172.146",
        "northing": "5931727.497",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 26
    },
    {
        "id": "H9007",
        "easting": "575567.404",
        "northing": "5932005.686",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 20
    },
    {
        "id": "H17964",
        "easting": "574799.829",
        "northing": "5931898.705",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Berg",
        "nr": 6
    },
    {
        "id": "H17969",
        "easting": "574659.729",
        "northing": "5931954.475",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Markt",
        "nr": 7
    },
    {
        "id": "H20563",
        "easting": "574710.478",
        "northing": "5931912.649",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Markt",
        "nr": 9
    },
    {
        "id": "H17375",
        "easting": "574550.011",
        "northing": "5931818.907",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Weg",
        "nr": 56
    },
    {
        "id": "H17377",
        "easting": "574633.894",
        "northing": "5931804.512",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 81
    },
    {
        "id": "H8981",
        "easting": "575311.393",
        "northing": "5931676.904",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 119
    },
    {
        "id": "H17992",
        "easting": "574958.939",
        "northing": "5931784.325",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 112
    },
    {
        "id": "H10533",
        "easting": "576066.63",
        "northing": "5932910.001",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Keitumer Weg",
        "nr": 8
    },
    {
        "id": "H10639",
        "easting": "576260.363",
        "northing": "5933021.186",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Keitumer Weg",
        "nr": 33
    },
    {
        "id": "H10065",
        "easting": "576233.138",
        "northing": "5932766.552",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lister Weg",
        "nr": 16
    },
    {
        "id": "H10640",
        "easting": "575993.858",
        "northing": "5933013.867",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 100
    },
    {
        "id": "H12428",
        "easting": "572274.612",
        "northing": "5929953.825",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H10959",
        "easting": "575548.943",
        "northing": "5933531.889",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Ohlwurt",
        "nr": 11
    },
    {
        "id": "H10897",
        "easting": "575835.003",
        "northing": "5933497.518",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Moosterhoop",
        "nr": 23
    },
    {
        "id": "H8805",
        "easting": "575991.55",
        "northing": "5931328.244",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Franz-Marc-Straße",
        "nr": 25
    },
    {
        "id": "H8807",
        "easting": "575992.459",
        "northing": "5931527.69",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Franz-Marc-Straße",
        "nr": 1
    },
    {
        "id": "H8925",
        "easting": "575938.919",
        "northing": "5931546.525",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 36
    },
    {
        "id": "H11002",
        "easting": "574993.18",
        "northing": "5933104.823",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Steinkamp",
        "nr": 11
    },
    {
        "id": "H10907",
        "easting": "575750.92",
        "northing": "5933334.848",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 269
    },
    {
        "id": "H8953",
        "easting": "575578.351",
        "northing": "5932056.157",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 47
    },
    {
        "id": "H9009",
        "easting": "575526.21",
        "northing": "5932055.817",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 53
    },
    {
        "id": "H8994",
        "easting": "575826.799",
        "northing": "5931779.225",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Klinger-Straße",
        "nr": 1
    },
    {
        "id": "H7800",
        "easting": "576315.121",
        "northing": "5931910.627",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kleine Holl",
        "nr": 3
    },
    {
        "id": "H7752",
        "easting": "576207.966",
        "northing": "5931730.156",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 23
    },
    {
        "id": "H9042",
        "easting": "575911.96",
        "northing": "5931826.356",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 61
    },
    {
        "id": "H21271",
        "easting": "573339.184",
        "northing": "5931884.597",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 111
    },
    {
        "id": "H9046",
        "easting": "575798.512",
        "northing": "5931827.198",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 57
    },
    {
        "id": "H9053",
        "easting": "575611.472",
        "northing": "5931809.335",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 56
    },
    {
        "id": "H9056",
        "easting": "575497.365",
        "northing": "5931810.941",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 48
    },
    {
        "id": "H9060",
        "easting": "575363.567",
        "northing": "5931827.455",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 34
    },
    {
        "id": "H9059",
        "easting": "575354.591",
        "northing": "5931812.589",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 34
    },
    {
        "id": "H18869",
        "easting": "575251.222",
        "northing": "5931907.588",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 19
    },
    {
        "id": "H17999",
        "easting": "575095.777",
        "northing": "5931867.311",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 14
    },
    {
        "id": "H18871",
        "easting": "575248.993",
        "northing": "5931839.93",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 21
    },
    {
        "id": "H8999",
        "easting": "575614.472",
        "northing": "5931867.885",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 5
    },
    {
        "id": "H9000",
        "easting": "575612.965",
        "northing": "5931953.495",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 17
    },
    {
        "id": "H9027",
        "easting": "575743.628",
        "northing": "5931994.344",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 131
    },
    {
        "id": "H11828",
        "easting": "575290.53",
        "northing": "5932379.55",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Dröögsiet",
        "nr": 6
    },
    {
        "id": "H11827",
        "easting": "575211.001",
        "northing": "5932330.851",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Dröögsiet",
        "nr": 14
    },
    {
        "id": "H11003",
        "easting": "574995.645",
        "northing": "5933541.703",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 77
    },
    {
        "id": "H10928",
        "easting": "575710.782",
        "northing": "5933544.206",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 28
    },
    {
        "id": "H11119",
        "easting": "575538.118",
        "northing": "5933561.606",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 39
    },
    {
        "id": "H12259",
        "easting": "571446.677",
        "northing": "5930511.896",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 33
    },
    {
        "id": "H12252",
        "easting": "571605.727",
        "northing": "5930350.231",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 37
    },
    {
        "id": "H12250",
        "easting": "571672.611",
        "northing": "5930283.147",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 37
    },
    {
        "id": "H8937",
        "easting": "575310.168",
        "northing": "5931537.449",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 2
    },
    {
        "id": "H11829",
        "easting": "575119.836",
        "northing": "5932254.554",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Dröögsiet",
        "nr": 28
    },
    {
        "id": "H11811",
        "easting": "575639.033",
        "northing": "5932547.757",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 66
    },
    {
        "id": "H11839",
        "easting": "575266.278",
        "northing": "5932473.869",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 48
    },
    {
        "id": "H11813",
        "easting": "575620.041",
        "northing": "5932500.048",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 71
    },
    {
        "id": "H11755",
        "easting": "575595.872",
        "northing": "5933036.075",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Tinnumer Weg",
        "nr": 9
    },
    {
        "id": "H10972",
        "easting": "575331.539",
        "northing": "5933391.563",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Bonhoefferstraße",
        "nr": 5
    },
    {
        "id": "H10860",
        "easting": "575066.939",
        "northing": "5933579.638",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 53
    },
    {
        "id": "H12271",
        "easting": "571214.12",
        "northing": "5930746.286",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 19
    },
    {
        "id": "H12275",
        "easting": "571062.527",
        "northing": "5930898.58",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 11
    },
    {
        "id": "H7541",
        "easting": "572313.925",
        "northing": "5931146.297",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 50
    },
    {
        "id": "H7575",
        "easting": "572341.464",
        "northing": "5931151.335",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 47
    },
    {
        "id": "H7783",
        "easting": "576241.45",
        "northing": "5931551.794",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 20
    },
    {
        "id": "H7740",
        "easting": "576205.445",
        "northing": "5931349.272",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 15
    },
    {
        "id": "H7738",
        "easting": "576207.618",
        "northing": "5931445.835",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 19
    },
    {
        "id": "H19308",
        "easting": "573563.381",
        "northing": "5931324.236",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 4
    },
    {
        "id": "H10719",
        "easting": "573525.705",
        "northing": "5931465.639",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 111
    },
    {
        "id": "H14398",
        "easting": "573438.689",
        "northing": "5931499.761",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 105
    },
    {
        "id": "H14400",
        "easting": "573360.369",
        "northing": "5931523.578",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 99
    },
    {
        "id": "H7581",
        "easting": "572871.648",
        "northing": "5930811.289",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 22
    },
    {
        "id": "H7617",
        "easting": "572707.323",
        "northing": "5930914.304",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 13
    },
    {
        "id": "H12968",
        "easting": "572330.021",
        "northing": "5930844.82",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 25
    },
    {
        "id": "H12982",
        "easting": "572001.567",
        "northing": "5931054.102",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 19
    },
    {
        "id": "H12987",
        "easting": "572559.343",
        "northing": "5930216.75",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Halskestraße",
        "nr": 67
    },
    {
        "id": "H19313",
        "easting": "573295.966",
        "northing": "5931014.419",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.126 Unterer Landweg",
        "nr": 0
    },
    {
        "id": "H19318",
        "easting": "573175.787",
        "northing": "5930873.639",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 0
    },
    {
        "id": "H19320",
        "easting": "573110.038",
        "northing": "5930792.388",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 0
    },
    {
        "id": "H19322",
        "easting": "573043.951",
        "northing": "5930710.187",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.126 Unterer Landweg",
        "nr": 0
    },
    {
        "id": "H8942",
        "easting": "575892.577",
        "northing": "5931565.53",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 33
    },
    {
        "id": "H11757",
        "easting": "575529.826",
        "northing": "5933009.253",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Morsumer Weg",
        "nr": 19
    },
    {
        "id": "H10705",
        "easting": "573574.972",
        "northing": "5931878.077",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Rote Brücke",
        "nr": 27
    },
    {
        "id": "H15793",
        "easting": "571579.69",
        "northing": "5931893.166",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 20
    },
    {
        "id": "H13122",
        "easting": "571307.789",
        "northing": "5931702.701",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 40
    },
    {
        "id": "H10643",
        "easting": "572993.926",
        "northing": "5931135.68",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 44
    },
    {
        "id": "H7597",
        "easting": "572987.988",
        "northing": "5931156.032",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 42
    },
    {
        "id": "H10727",
        "easting": "573638.589",
        "northing": "5931452.315",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 337
    },
    {
        "id": "H11230",
        "easting": "573687.908",
        "northing": "5931529.418",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 329
    },
    {
        "id": "H7593",
        "easting": "573098.139",
        "northing": "5931076.067",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 47
    },
    {
        "id": "H7601",
        "easting": "572931.389",
        "northing": "5931181.504",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 42
    },
    {
        "id": "H7557",
        "easting": "572531.611",
        "northing": "5931702.547",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 40
    },
    {
        "id": "H9953",
        "easting": "572609.112",
        "northing": "5931743.788",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 63
    },
    {
        "id": "H14412",
        "easting": "572822.062",
        "northing": "5931681.797",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 68
    },
    {
        "id": "H12974",
        "easting": "572160.492",
        "northing": "5930954.111",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 18
    },
    {
        "id": "H12273",
        "easting": "571138.263",
        "northing": "5930821.859",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 15
    },
    {
        "id": "H13039",
        "easting": "571538.872",
        "northing": "5930999.226",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 36
    },
    {
        "id": "H7521",
        "easting": "572828.613",
        "northing": "5932622.337",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 0
    },
    {
        "id": "H17754",
        "easting": "572032.523",
        "northing": "5933231.229",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H17756",
        "easting": "572205.777",
        "northing": "5933129.544",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H12436",
        "easting": "572308.092",
        "northing": "5930231.939",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 63
    },
    {
        "id": "H13010",
        "easting": "572841.759",
        "northing": "5930426.336",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 16
    },
    {
        "id": "H13013",
        "easting": "572760.039",
        "northing": "5930335.921",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 34
    },
    {
        "id": "H11945",
        "easting": "572463.594",
        "northing": "5930097.788",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "KLGV.604 Moorfleet",
        "nr": 3
    },
    {
        "id": "H14402",
        "easting": "573260.508",
        "northing": "5931552.594",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 93
    },
    {
        "id": "H19337",
        "easting": "572952.188",
        "northing": "5930581.276",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Unterer Landweg",
        "nr": 93
    },
    {
        "id": "H7589",
        "easting": "573238.058",
        "northing": "5930967.784",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 55
    },
    {
        "id": "H7578",
        "easting": "573084.743",
        "northing": "5930975.455",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 31
    },
    {
        "id": "H7580",
        "easting": "572956.066",
        "northing": "5930821.383",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 25
    },
    {
        "id": "H17753",
        "easting": "571942.862",
        "northing": "5933278.361",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H8290",
        "easting": "576366.377",
        "northing": "5932290.848",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Am Mühlenbach",
        "nr": 26
    },
    {
        "id": "H9080",
        "easting": "575928.145",
        "northing": "5932165.046",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mondrianweg",
        "nr": 4
    },
    {
        "id": "H11844",
        "easting": "575010.788",
        "northing": "5932206.462",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 13
    },
    {
        "id": "H9020",
        "easting": "575571.384",
        "northing": "5932125.769",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 67
    },
    {
        "id": "H11843",
        "easting": "575075.372",
        "northing": "5932297.296",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 28
    },
    {
        "id": "H11850",
        "easting": "574990.724",
        "northing": "5932460.753",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 2
    },
    {
        "id": "H10720",
        "easting": "573586.291",
        "northing": "5931405.567",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 340
    },
    {
        "id": "H7582",
        "easting": "572785.772",
        "northing": "5930864.07",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 15
    },
    {
        "id": "H12950",
        "easting": "572033.608",
        "northing": "5930567.025",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 54
    },
    {
        "id": "H7603",
        "easting": "572845.602",
        "northing": "5931232.457",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 40
    },
    {
        "id": "H12965",
        "easting": "572414.33",
        "northing": "5930791.08",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 29
    },
    {
        "id": "H12961",
        "easting": "572500.477",
        "northing": "5930736.469",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 29
    },
    {
        "id": "H12981",
        "easting": "572046.521",
        "northing": "5931010.71",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 16
    },
    {
        "id": "H12984",
        "easting": "571973.667",
        "northing": "5931058.349",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 12
    },
    {
        "id": "H8778",
        "easting": "575705.688",
        "northing": "5931449.498",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 11
    },
    {
        "id": "H19309",
        "easting": "573496.58",
        "northing": "5931243.631",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 16
    },
    {
        "id": "H12236",
        "easting": "571996.362",
        "northing": "5929951.912",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 47
    },
    {
        "id": "H12426",
        "easting": "572220.97",
        "northing": "5929878.934",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 72
    },
    {
        "id": "H13113",
        "easting": "571195.668",
        "northing": "5931597.783",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 71
    },
    {
        "id": "H12423",
        "easting": "572167.868",
        "northing": "5929804.773",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 76
    },
    {
        "id": "H13026",
        "easting": "572728.848",
        "northing": "5930226.074",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 48
    },
    {
        "id": "H13024",
        "easting": "572730.848",
        "northing": "5930247.466",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 41
    },
    {
        "id": "H7547",
        "easting": "572423.762",
        "northing": "5931378.209",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 11
    },
    {
        "id": "H7552",
        "easting": "572454.98",
        "northing": "5931353.808",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 16
    },
    {
        "id": "H13034",
        "easting": "571725.582",
        "northing": "5930844.184",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 44
    },
    {
        "id": "H13032",
        "easting": "571807.942",
        "northing": "5930779.009",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 46
    },
    {
        "id": "H13030",
        "easting": "571884.105",
        "northing": "5930714.205",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 48
    },
    {
        "id": "H11960",
        "easting": "571440.802",
        "northing": "5931761.416",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 51
    },
    {
        "id": "H586",
        "easting": "573205.727",
        "northing": "5929934.756",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "KLGV.601 Billbrook Dorf",
        "nr": 49
    },
    {
        "id": "H13072",
        "easting": "571850.393",
        "northing": "5931136.751",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 10
    },
    {
        "id": "H13092",
        "easting": "571558.93",
        "northing": "5931440.076",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 4
    },
    {
        "id": "H13106",
        "easting": "571697.226",
        "northing": "5931465.337",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 4
    },
    {
        "id": "H19564",
        "easting": "571833.288",
        "northing": "5932620.316",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 4
    },
    {
        "id": "H2913",
        "easting": "574382.149",
        "northing": "5930574.089",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 90
    },
    {
        "id": "H13125",
        "easting": "571262.075",
        "northing": "5931668.915",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 57
    },
    {
        "id": "H11057",
        "easting": "570804.464",
        "northing": "5931136.537",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 9
    },
    {
        "id": "H585",
        "easting": "573307.403",
        "northing": "5929791.139",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Dweerlandweg",
        "nr": 0
    },
    {
        "id": "H19558",
        "easting": "571809.676",
        "northing": "5932527.994",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 5
    },
    {
        "id": "H9057",
        "easting": "575470.096",
        "northing": "5931826.626",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 41
    },
    {
        "id": "H19483",
        "easting": "571387.271",
        "northing": "5932441.781",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 9
    },
    {
        "id": "H11062",
        "easting": "571536.832",
        "northing": "5929983.797",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 31
    },
    {
        "id": "H11068",
        "easting": "571696.46",
        "northing": "5929903.78",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 41
    },
    {
        "id": "H11024",
        "easting": "571300.601",
        "northing": "5929606.85",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 165
    },
    {
        "id": "H19565",
        "easting": "571835.219",
        "northing": "5932690.897",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 9
    },
    {
        "id": "H21819",
        "easting": "571638.981",
        "northing": "5932016.498",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 32
    },
    {
        "id": "H13088",
        "easting": "571615.011",
        "northing": "5931335.309",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 6
    },
    {
        "id": "H13083",
        "easting": "571663.1",
        "northing": "5931283.285",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 9
    },
    {
        "id": "H13082",
        "easting": "571713.559",
        "northing": "5931237.489",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 6
    },
    {
        "id": "H19813",
        "easting": "574323.362",
        "northing": "5930653.367",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 80
    },
    {
        "id": "H19816",
        "easting": "574167.811",
        "northing": "5930864.907",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 62
    },
    {
        "id": "H19821",
        "easting": "573720.734",
        "northing": "5931096.372",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 25
    },
    {
        "id": "H19822",
        "easting": "573653.964",
        "northing": "5931193.723",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 11
    },
    {
        "id": "H19817",
        "easting": "574056.3",
        "northing": "5930922.019",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 55
    },
    {
        "id": "H19815",
        "easting": "574249.792",
        "northing": "5930748.209",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 72
    },
    {
        "id": "H19818",
        "easting": "573999.504",
        "northing": "5930944.242",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 48
    },
    {
        "id": "H13046",
        "easting": "572277.316",
        "northing": "5929728.165",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Unterer Landweg",
        "nr": 77
    },
    {
        "id": "H15421",
        "easting": "572317.721",
        "northing": "5929781.694",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Unterer Landweg",
        "nr": 81
    },
    {
        "id": "H10991",
        "easting": "572039.751",
        "northing": "5929658.581",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 121
    },
    {
        "id": "H13649",
        "easting": "571972.099",
        "northing": "5928786.333",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 10
    },
    {
        "id": "H13043",
        "easting": "572177.474",
        "northing": "5929772.266",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 63
    },
    {
        "id": "H19491",
        "easting": "571737.7",
        "northing": "5932714.962",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 38
    },
    {
        "id": "H11047",
        "easting": "570910.815",
        "northing": "5930398.779",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 73
    },
    {
        "id": "H11053",
        "easting": "570780.836",
        "northing": "5930841.219",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 33
    },
    {
        "id": "H11055",
        "easting": "570766.942",
        "northing": "5931053.88",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 17
    },
    {
        "id": "H16521",
        "easting": "574637.466",
        "northing": "5933936.404",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mattkamp",
        "nr": 27
    },
    {
        "id": "H10986",
        "easting": "574931.5",
        "northing": "5933218.248",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 23
    },
    {
        "id": "H10956",
        "easting": "571739.082",
        "northing": "5928543.91",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 271
    },
    {
        "id": "H9026",
        "easting": "575677.474",
        "northing": "5932061.847",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 119
    },
    {
        "id": "H10982",
        "easting": "575084.693",
        "northing": "5933255.126",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 34
    },
    {
        "id": "H11007",
        "easting": "571418.029",
        "northing": "5929333.654",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 0
    },
    {
        "id": "H19486",
        "easting": "571509.807",
        "northing": "5932601.404",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 21
    },
    {
        "id": "H13551",
        "easting": "574933.013",
        "northing": "5933069.186",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Dudenweg",
        "nr": 6
    },
    {
        "id": "H10950",
        "easting": "571993.501",
        "northing": "5928387.993",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 296
    },
    {
        "id": "H10952",
        "easting": "571803.897",
        "northing": "5928503.536",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 276
    },
    {
        "id": "H16464",
        "easting": "574651.945",
        "northing": "5933652.897",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mattkamp",
        "nr": 4
    },
    {
        "id": "H13084",
        "easting": "571662.961",
        "northing": "5931301.339",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 9
    },
    {
        "id": "H13080",
        "easting": "571737.05",
        "northing": "5931232.321",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 11
    },
    {
        "id": "H16543",
        "easting": "574360.568",
        "northing": "5933419.008",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 65
    },
    {
        "id": "H16524",
        "easting": "574894.537",
        "northing": "5933510.298",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Am Kampmoor",
        "nr": 9
    },
    {
        "id": "H16526",
        "easting": "574822.036",
        "northing": "5933475.834",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Am Kampmoor",
        "nr": 1
    },
    {
        "id": "H16451",
        "easting": "573875.874",
        "northing": "5933191.16",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 34
    },
    {
        "id": "H10914",
        "easting": "575550.388",
        "northing": "5933218.644",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 235
    },
    {
        "id": "H7586",
        "easting": "572433.161",
        "northing": "5931089.99",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 1
    },
    {
        "id": "H14240",
        "easting": "574203.099",
        "northing": "5932162.625",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Korverweg",
        "nr": 28
    },
    {
        "id": "H19363",
        "easting": "571699.568",
        "northing": "5932218.006",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 24
    },
    {
        "id": "H3879",
        "easting": "571901.609",
        "northing": "5932284.691",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 33
    },
    {
        "id": "H3873",
        "easting": "572205.542",
        "northing": "5932199.813",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 45
    },
    {
        "id": "H20381",
        "easting": "571828.163",
        "northing": "5931962.917",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 13
    },
    {
        "id": "H20382",
        "easting": "571685.032",
        "northing": "5932010.539",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 7
    },
    {
        "id": "H21334",
        "easting": "571722.059",
        "northing": "5932334.831",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 24
    },
    {
        "id": "H21321",
        "easting": "572465.471",
        "northing": "5932329.37",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 39
    },
    {
        "id": "H21324",
        "easting": "572342.039",
        "northing": "5932363.679",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 35
    },
    {
        "id": "H19581",
        "easting": "572664.456",
        "northing": "5932257.68",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 54
    },
    {
        "id": "H19579",
        "easting": "572568.772",
        "northing": "5932284.603",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 42
    },
    {
        "id": "H19354",
        "easting": "571747.729",
        "northing": "5932395.157",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 8
    },
    {
        "id": "H21329",
        "easting": "572116.556",
        "northing": "5932426.449",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 19
    },
    {
        "id": "H21921",
        "easting": "571728.904",
        "northing": "5931989.007",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 7
    },
    {
        "id": "H13881",
        "easting": "576216.428",
        "northing": "5934077.33",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "KLGV.154 Merkenstraße",
        "nr": 0
    },
    {
        "id": "H17373",
        "easting": "574350.386",
        "northing": "5931920.846",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Weg",
        "nr": 28
    },
    {
        "id": "H3841",
        "easting": "576374.442",
        "northing": "5933301.234",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Willinghusener Weg",
        "nr": 0
    },
    {
        "id": "H14203",
        "easting": "574674.67",
        "northing": "5932130.315",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 93
    },
    {
        "id": "H10991",
        "easting": "575088.135",
        "northing": "5933362.12",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glitzaweg",
        "nr": 12
    },
    {
        "id": "H13882",
        "easting": "576254.575",
        "northing": "5934185.608",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hegenredder",
        "nr": 0
    },
    {
        "id": "H13883",
        "easting": "576285.604",
        "northing": "5934275.873",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hegenredder",
        "nr": 34
    },
    {
        "id": "H19505",
        "easting": "572510.924",
        "northing": "5932533.796",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 134
    },
    {
        "id": "H19507",
        "easting": "572604.079",
        "northing": "5932500.955",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 141
    },
    {
        "id": "H19552",
        "easting": "572018.514",
        "northing": "5932457.616",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 11
    },
    {
        "id": "H19555",
        "easting": "571913.844",
        "northing": "5932486.783",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 4
    },
    {
        "id": "H13127",
        "easting": "571116.188",
        "northing": "5931535.817",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 73
    },
    {
        "id": "H20371",
        "easting": "572424.896",
        "northing": "5931786.878",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 45
    },
    {
        "id": "H20372",
        "easting": "572323.465",
        "northing": "5931816.81",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 30
    },
    {
        "id": "H20374",
        "easting": "572230.082",
        "northing": "5931844.391",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 30
    },
    {
        "id": "H20376",
        "easting": "572041.295",
        "northing": "5931899.522",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 29
    },
    {
        "id": "H20377",
        "easting": "571946.022",
        "northing": "5931927.852",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 17
    },
    {
        "id": "H13118",
        "easting": "571280.428",
        "northing": "5931659.188",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 57
    },
    {
        "id": "H10739",
        "easting": "571662.122",
        "northing": "5932374.324",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 8
    },
    {
        "id": "H7501",
        "easting": "572785.535",
        "northing": "5932363.99",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 15
    },
    {
        "id": "H21736",
        "easting": "571036.888",
        "northing": "5931478.417",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 54
    },
    {
        "id": "H21010",
        "easting": "575756.65",
        "northing": "5932531.85",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 79
    },
    {
        "id": "H14210",
        "easting": "574605.751",
        "northing": "5932265.424",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Borchardsheide",
        "nr": 6
    },
    {
        "id": "H9065",
        "easting": "576067.616",
        "northing": "5931592.447",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Oskar-Schlemmer-Straße",
        "nr": 14
    },
    {
        "id": "H21740",
        "easting": "571117.177",
        "northing": "5931539.465",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 73
    },
    {
        "id": "H11237",
        "easting": "573657.16",
        "northing": "5931926.979",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 285
    },
    {
        "id": "H10995",
        "easting": "572128.542",
        "northing": "5929779.163",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 61
    },
    {
        "id": "H9071",
        "easting": "575946.968",
        "northing": "5931696.658",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Ittenstraße",
        "nr": 9
    },
    {
        "id": "H8806",
        "easting": "575992.084",
        "northing": "5931432.306",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Franz-Marc-Straße",
        "nr": 11
    },
    {
        "id": "H19502",
        "easting": "572422.728",
        "northing": "5932565.397",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 122
    },
    {
        "id": "H13117",
        "easting": "571264.164",
        "northing": "5931647.703",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 59
    },
    {
        "id": "H8289",
        "easting": "576225.482",
        "northing": "5932127.143",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 29
    },
    {
        "id": "H8951",
        "easting": "576045.191",
        "northing": "5931080.263",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 191
    },
    {
        "id": "H8947",
        "easting": "576069.236",
        "northing": "5931237.269",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 203
    },
    {
        "id": "H7766",
        "easting": "576479.269",
        "northing": "5931396.549",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 16
    },
    {
        "id": "H11840",
        "easting": "575183.55",
        "northing": "5932422.641",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 44
    },
    {
        "id": "H8945",
        "easting": "576165.669",
        "northing": "5931572.69",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 55
    },
    {
        "id": "H358",
        "easting": "575841.714",
        "northing": "5932655.318",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Niebüllweg",
        "nr": 12
    },
    {
        "id": "H359",
        "easting": "575792.183",
        "northing": "5932635.504",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Niebüllweg",
        "nr": 12
    },
    {
        "id": "H9545",
        "easting": "575861.989",
        "northing": "5932584.153",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 85
    },
    {
        "id": "H8278",
        "easting": "576713.434",
        "northing": "5930554.642",
        "plz": 22115,
        "ortsteil": "Lohbrügge",
        "str": "Asbrookdamm",
        "nr": 1
    },
    {
        "id": "H13028",
        "easting": "572592.987",
        "northing": "5930149.727",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "KLGV.604 Moorfleet",
        "nr": 18
    },
    {
        "id": "H14340",
        "easting": "574428.772",
        "northing": "5932850.349",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Papyrusweg",
        "nr": 21
    },
    {
        "id": "H16627",
        "easting": "574050.725",
        "northing": "5933086.668",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 50
    },
    {
        "id": "H14273",
        "easting": "574004.555",
        "northing": "5932486.569",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Mühlenweg",
        "nr": 27
    },
    {
        "id": "H2909",
        "easting": "574688.346",
        "northing": "5930180.218",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 126
    },
    {
        "id": "H10935",
        "easting": "572467.601",
        "northing": "5927599.268",
        "plz": 22113,
        "ortsteil": "Allermöhe",
        "str": "Moorfleeter Deich",
        "nr": 377
    },
    {
        "id": "H10937",
        "easting": "572444.644",
        "northing": "5927706.885",
        "plz": 22113,
        "ortsteil": "Allermöhe",
        "str": "Moorfleeter Deich",
        "nr": 371
    },
    {
        "id": "H12202",
        "easting": "571841.627",
        "northing": "5929960.387",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Hein-Baxmann-Stieg",
        "nr": 13
    },
    {
        "id": "H11029",
        "easting": "571064.759",
        "northing": "5929582.593",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Holzhafenufer",
        "nr": 8
    },
    {
        "id": "H11061",
        "easting": "571419.657",
        "northing": "5930003.42",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 21
    },
    {
        "id": "H10939",
        "easting": "572415.68",
        "northing": "5927909.011",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 356
    },
    {
        "id": "H11955",
        "easting": "571399.242",
        "northing": "5931618.871",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 55
    },
    {
        "id": "H12433",
        "easting": "572350.76",
        "northing": "5930128.947",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 0
    },
    {
        "id": "H13161",
        "easting": "570885.666",
        "northing": "5931438.049",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.117 Tiefstack-Süd",
        "nr": 2
    },
    {
        "id": "H14271",
        "easting": "573985.565",
        "northing": "5932389.615",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Mühlenweg",
        "nr": 16
    },
    {
        "id": "H2910",
        "easting": "574599.32",
        "northing": "5930296.906",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 116
    },
    {
        "id": "H13599",
        "easting": "572474.215",
        "northing": "5928508.866",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 0
    },
    {
        "id": "H13596",
        "easting": "572656.726",
        "northing": "5928230.787",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Amandus-Stubbe-Straße",
        "nr": 0
    },
    {
        "id": "H12976",
        "easting": "572085.118",
        "northing": "5931000.947",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 21
    },
    {
        "id": "H10938",
        "easting": "572422.486",
        "northing": "5927812.331",
        "plz": 22113,
        "ortsteil": "Allermöhe",
        "str": "Moorfleeter Deich",
        "nr": 359
    },
    {
        "id": "H4861",
        "easting": "571496.758",
        "northing": "5932401.03",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 18
    },
    {
        "id": "H11034",
        "easting": "571216.693",
        "northing": "5929980.251",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 129
    },
    {
        "id": "H10962",
        "easting": "571623.626",
        "northing": "5928485.158",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Schleusenweg",
        "nr": 10
    },
    {
        "id": "H11014",
        "easting": "571563.108",
        "northing": "5929551.471",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Kirchenweg",
        "nr": 26
    },
    {
        "id": "H11008",
        "easting": "571388.302",
        "northing": "5929397.048",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 191
    },
    {
        "id": "H14233",
        "easting": "574383.262",
        "northing": "5932278.692",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 63
    },
    {
        "id": "H11947",
        "easting": "574218.551",
        "northing": "5932381.911",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 36
    },
    {
        "id": "H10838",
        "easting": "575597.997",
        "northing": "5933687.553",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbargenredder",
        "nr": 31
    },
    {
        "id": "H10724",
        "easting": "571498.752",
        "northing": "5928976.363",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 231
    },
    {
        "id": "H13058",
        "easting": "571466.484",
        "northing": "5931074.345",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 34
    },
    {
        "id": "H11784",
        "easting": "575022.915",
        "northing": "5933040.859",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Steinkamp",
        "nr": 17
    },
    {
        "id": "H11022",
        "easting": "571313.021",
        "northing": "5929376.067",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 191
    },
    {
        "id": "H12992",
        "easting": "572669.813",
        "northing": "5930293.858",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 44
    },
    {
        "id": "H14242",
        "easting": "574177.499",
        "northing": "5932206.948",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Arndesstieg",
        "nr": 17
    },
    {
        "id": "H10973",
        "easting": "571678.03",
        "northing": "5928770.76",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 6
    },
    {
        "id": "H4022",
        "easting": "577453.551",
        "northing": "5932599.135",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Stormarnstraße",
        "nr": 0
    },
    {
        "id": "H10722",
        "easting": "571557.337",
        "northing": "5928780.966",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 245
    },
    {
        "id": "H7791",
        "easting": "576376.886",
        "northing": "5931607.15",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Lietbargredder",
        "nr": 5
    },
    {
        "id": "H17371",
        "easting": "573744.125",
        "northing": "5931983.339",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Rote Brücke",
        "nr": 13
    },
    {
        "id": "H8295",
        "easting": "576114.077",
        "northing": "5931297.834",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 11
    },
    {
        "id": "H10911",
        "easting": "575615.148",
        "northing": "5933259.109",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 248
    },
    {
        "id": "H16473",
        "easting": "574488.831",
        "northing": "5933024.709",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Pergamentweg",
        "nr": 52
    },
    {
        "id": "H17372",
        "easting": "574241.363",
        "northing": "5931971.958",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Weg",
        "nr": 21
    },
    {
        "id": "H10977",
        "easting": "571716.017",
        "northing": "5929052.79",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 0
    },
    {
        "id": "H12968",
        "easting": "573600.769",
        "northing": "5929587.171",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Dweerlandweg",
        "nr": 0
    },
    {
        "id": "H11005",
        "easting": "575339.272",
        "northing": "5932477.956",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 54
    },
    {
        "id": "H10742",
        "easting": "571060.168",
        "northing": "5928534.637",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 46
    },
    {
        "id": "H11720",
        "easting": "575801.64",
        "northing": "5933039.08",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaltenbergen",
        "nr": 35
    },
    {
        "id": "H13098",
        "easting": "571569.208",
        "northing": "5931475.175",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 1
    },
    {
        "id": "H10740",
        "easting": "571218.549",
        "northing": "5928504.439",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 36
    },
    {
        "id": "H8269",
        "easting": "576340.536",
        "northing": "5930878.322",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Asbrookdamm",
        "nr": 38
    },
    {
        "id": "H10858",
        "easting": "575106.245",
        "northing": "5933584.516",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Salomon-Petri-Ring",
        "nr": 65
    },
    {
        "id": "H11820",
        "easting": "575473.394",
        "northing": "5932496.453",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 81
    },
    {
        "id": "H11754",
        "easting": "575605.146",
        "northing": "5932985.773",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Tinnumer Weg",
        "nr": 7
    },
    {
        "id": "H12277",
        "easting": "570975.269",
        "northing": "5930987.605",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 9
    },
    {
        "id": "H16531",
        "easting": "574780.544",
        "northing": "5933293.356",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Baxmannweg",
        "nr": 3
    },
    {
        "id": "H16544",
        "easting": "574459.283",
        "northing": "5933431.658",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 73
    },
    {
        "id": "H16478",
        "easting": "574549.706",
        "northing": "5933066.22",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Cottaweg",
        "nr": 35
    },
    {
        "id": "H8798",
        "easting": "575946.764",
        "northing": "5931278.504",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 58
    },
    {
        "id": "H17975",
        "easting": "574747.729",
        "northing": "5931985.107",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 9
    },
    {
        "id": "H8283",
        "easting": "576240.616",
        "northing": "5931124.992",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 2
    },
    {
        "id": "H18006",
        "easting": "575127.555",
        "northing": "5931657.602",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 146
    },
    {
        "id": "H8960",
        "easting": "575595.803",
        "northing": "5931276.918",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 157
    },
    {
        "id": "H8956",
        "easting": "575846.589",
        "northing": "5931142.641",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 183
    },
    {
        "id": "H11940",
        "easting": "572358.106",
        "northing": "5931384.578",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 12
    },
    {
        "id": "H7542",
        "easting": "572372.321",
        "northing": "5931235.534",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 46
    },
    {
        "id": "H7742",
        "easting": "576192.208",
        "northing": "5931551.216",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 48
    },
    {
        "id": "H15851",
        "easting": "574052.217",
        "northing": "5932693.954",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 96
    },
    {
        "id": "H7533",
        "easting": "572589.093",
        "northing": "5931851.521",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 34
    },
    {
        "id": "H10910",
        "easting": "575636.641",
        "northing": "5933287.428",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 251
    },
    {
        "id": "H13148",
        "easting": "570870.85",
        "northing": "5931163.747",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 0
    },
    {
        "id": "H14406",
        "easting": "573144.632",
        "northing": "5931586.74",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 85
    },
    {
        "id": "H11310",
        "easting": "576246.076",
        "northing": "5932167.397",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Hollkoppelweg",
        "nr": 19
    },
    {
        "id": "H19310",
        "easting": "573431.157",
        "northing": "5931165.554",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 26
    },
    {
        "id": "H10915",
        "easting": "575515.707",
        "northing": "5933177.409",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 232
    },
    {
        "id": "H10918",
        "easting": "575399.61",
        "northing": "5933095.577",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 217
    },
    {
        "id": "H9035",
        "easting": "575874.18",
        "northing": "5931981.149",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Edvard-Munch-Straße",
        "nr": 15
    },
    {
        "id": "H8996",
        "easting": "575394.02",
        "northing": "5931698.387",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Haseneck",
        "nr": 11
    },
    {
        "id": "H21206",
        "easting": "573582.989",
        "northing": "5931683.508",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Zinkhüttenweg",
        "nr": 6
    },
    {
        "id": "H16559",
        "easting": "574726.403",
        "northing": "5933196.195",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 31
    },
    {
        "id": "H16273",
        "easting": "573739.034",
        "northing": "5932596.287",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 15
    },
    {
        "id": "H19525",
        "easting": "573644.905",
        "northing": "5932090.876",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 270
    },
    {
        "id": "H10952",
        "easting": "575417.032",
        "northing": "5933403.551",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 13
    },
    {
        "id": "H7611",
        "easting": "572656.503",
        "northing": "5931295.81",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 20
    },
    {
        "id": "H10984",
        "easting": "575017.856",
        "northing": "5933233.913",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Öjendorfer Höhe",
        "nr": 30
    },
    {
        "id": "H10324",
        "easting": "576326.825",
        "northing": "5932828.258",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lister Weg",
        "nr": 30
    },
    {
        "id": "H10997",
        "easting": "575156.157",
        "northing": "5933068.932",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Heinrich-Schulte-Höhe",
        "nr": 26
    },
    {
        "id": "H7567",
        "easting": "572212.125",
        "northing": "5930961.224",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 52
    },
    {
        "id": "H12959",
        "easting": "572584.392",
        "northing": "5930682.885",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 31
    },
    {
        "id": "H12439",
        "easting": "572228.525",
        "northing": "5930326.444",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 63
    },
    {
        "id": "H19317",
        "easting": "573236.728",
        "northing": "5930945.572",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Unterer Landweg",
        "nr": 25
    },
    {
        "id": "H15840",
        "easting": "574531.577",
        "northing": "5932767.347",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 132
    },
    {
        "id": "H14326",
        "easting": "574247.416",
        "northing": "5933016.137",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Pergamentweg",
        "nr": 1
    },
    {
        "id": "H7807",
        "easting": "576397.187",
        "northing": "5931973.301",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 64
    },
    {
        "id": "H10700",
        "easting": "576068.126",
        "northing": "5931721.956",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Oskar-Schlemmer-Straße",
        "nr": 17
    },
    {
        "id": "H10715",
        "easting": "573580.042",
        "northing": "5931504.26",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 107
    },
    {
        "id": "H16342",
        "easting": "573658.056",
        "northing": "5932742.094",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 61
    },
    {
        "id": "H6779",
        "easting": "573789.431",
        "northing": "5932833.053",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 18
    },
    {
        "id": "H10799",
        "easting": "576160.284",
        "northing": "5933115.633",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 122
    },
    {
        "id": "H19526",
        "easting": "573637.958",
        "northing": "5932000.56",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 277
    },
    {
        "id": "H11231",
        "easting": "573757.379",
        "northing": "5931619.737",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 323
    },
    {
        "id": "H10706",
        "easting": "573481.779",
        "northing": "5931844.309",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Rote Brücke",
        "nr": 30
    },
    {
        "id": "H15877",
        "easting": "571593.737",
        "northing": "5931938.168",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 2
    },
    {
        "id": "H11738",
        "easting": "575525.311",
        "northing": "5932754.974",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 44
    },
    {
        "id": "H15821",
        "easting": "574537.759",
        "northing": "5932666.447",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 84
    },
    {
        "id": "H15804",
        "easting": "574138.291",
        "northing": "5932583.16",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oberschleems",
        "nr": 13
    },
    {
        "id": "H16221",
        "easting": "573895.561",
        "northing": "5933305.995",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Liebezeitstraße",
        "nr": 21
    },
    {
        "id": "H11808",
        "easting": "574924.072",
        "northing": "5932582.97",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Landjägerstieg",
        "nr": 4
    },
    {
        "id": "H11790",
        "easting": "574934.925",
        "northing": "5932834.817",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 162
    },
    {
        "id": "H5460",
        "easting": "575192.219",
        "northing": "5932947.779",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 191
    },
    {
        "id": "H12239",
        "easting": "571921.4",
        "northing": "5930027.831",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 47
    },
    {
        "id": "H8926",
        "easting": "575816.982",
        "northing": "5931545.377",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 28
    },
    {
        "id": "H11758",
        "easting": "575582.052",
        "northing": "5932891.076",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Morsumer Weg",
        "nr": 9
    },
    {
        "id": "H17737",
        "easting": "574361.72",
        "northing": "5931988.541",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 43
    },
    {
        "id": "H17974",
        "easting": "574435.888",
        "northing": "5932009.804",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lohkamp",
        "nr": 1
    },
    {
        "id": "H17358",
        "easting": "573903.708",
        "northing": "5931985.465",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Rotenbrückenweg",
        "nr": 7
    },
    {
        "id": "H10710",
        "easting": "573472.032",
        "northing": "5931779.785",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 93
    },
    {
        "id": "H15807",
        "easting": "574181.502",
        "northing": "5932682.963",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oberschleems",
        "nr": 9
    },
    {
        "id": "H10347",
        "easting": "576301.556",
        "northing": "5932929.6",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Lister Weg",
        "nr": 40
    },
    {
        "id": "H10961",
        "easting": "575527.939",
        "northing": "5933468.276",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Ohlwurt",
        "nr": 6
    },
    {
        "id": "H16278",
        "easting": "573736.633",
        "northing": "5932438.303",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schiffbeker Schanze",
        "nr": 13
    },
    {
        "id": "H8811",
        "easting": "575871.385",
        "northing": "5931418.813",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Münterweg",
        "nr": 13
    },
    {
        "id": "H17725",
        "easting": "574637.8641",
        "northing": "5931825.041",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 79
    },
    {
        "id": "H7775",
        "easting": "576315.574",
        "northing": "5931239.653",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Bienenbusch",
        "nr": 15
    },
    {
        "id": "H7787",
        "easting": "576240.428",
        "northing": "5931648.392",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 0
    },
    {
        "id": "H7794",
        "easting": "576409.11",
        "northing": "5931681.513",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 6
    },
    {
        "id": "H9957",
        "easting": "572707.221",
        "northing": "5931716.345",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 63
    },
    {
        "id": "H12272",
        "easting": "571297.169",
        "northing": "5930632.854",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 19
    },
    {
        "id": "H14306",
        "easting": "574657.997",
        "northing": "5932809.378",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 149
    },
    {
        "id": "H14312",
        "easting": "574329.596",
        "northing": "5932760.358",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 121
    },
    {
        "id": "H16260",
        "easting": "573724.529",
        "northing": "5932368.118",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 121
    },
    {
        "id": "H19523",
        "easting": "573630.341",
        "northing": "5932194.867",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 262
    },
    {
        "id": "H18426",
        "easting": "574773.991",
        "northing": "5932347.333",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Pastor-Manzke-Weg",
        "nr": 6
    },
    {
        "id": "H11730",
        "easting": "575805.706",
        "northing": "5932909.823",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 81
    },
    {
        "id": "H9554",
        "easting": "576071.168",
        "northing": "5932693.898",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 24
    },
    {
        "id": "H15800",
        "easting": "573946.692",
        "northing": "5932619.352",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 4
    },
    {
        "id": "H18433",
        "easting": "572556.113",
        "northing": "5932916.654",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Kolumbusstraße",
        "nr": 14
    },
    {
        "id": "H16313",
        "easting": "573500.28",
        "northing": "5932553.756",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 106
    },
    {
        "id": "H14279",
        "easting": "574059.294",
        "northing": "5932446.172",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oberschleems",
        "nr": 30
    },
    {
        "id": "H11349",
        "easting": "573746.793",
        "northing": "5933202.276",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 22
    },
    {
        "id": "H16285",
        "easting": "573641.635",
        "northing": "5932517.252",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schiffbeker Schanze",
        "nr": 1
    },
    {
        "id": "H15828",
        "easting": "574748.816",
        "northing": "5932710.107",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 83
    },
    {
        "id": "H8260",
        "easting": "576477.45",
        "northing": "5931054.672",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 28
    },
    {
        "id": "H16250",
        "easting": "573795.19",
        "northing": "5932284.409",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 127
    },
    {
        "id": "H16222",
        "easting": "574015.585",
        "northing": "5933296.276",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Liebezeitstraße",
        "nr": 9
    },
    {
        "id": "H14249",
        "easting": "574162.084",
        "northing": "5932354.223",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schöfferstieg",
        "nr": 6
    },
    {
        "id": "H10874",
        "easting": "575521.674",
        "northing": "5933589.144",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 39
    },
    {
        "id": "H14331",
        "easting": "574172.29",
        "northing": "5932948.196",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemkoppel",
        "nr": 11
    },
    {
        "id": "H355",
        "easting": "575932.268",
        "northing": "5932768.32",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Niebüllweg",
        "nr": 9
    },
    {
        "id": "H357",
        "easting": "575925.242",
        "northing": "5932709.101",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Niebüllweg",
        "nr": 10
    },
    {
        "id": "H11819",
        "easting": "575416.314",
        "northing": "5932431.274",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Erhard-Dressel-Bogen",
        "nr": 14
    },
    {
        "id": "H8261",
        "easting": "576547.147",
        "northing": 5931009,
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 42
    },
    {
        "id": "H9032",
        "easting": "575680.031",
        "northing": "5931956.326",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 14
    },
    {
        "id": "H9082",
        "easting": "575860.379",
        "northing": "5932213.656",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Pechstein-Straße",
        "nr": 39
    },
    {
        "id": "H11795",
        "easting": "575263.278",
        "northing": "5932612.042",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 30
    },
    {
        "id": "H9546",
        "easting": "575966.949",
        "northing": "5932610.116",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 95
    },
    {
        "id": "H17976",
        "easting": "574869.433",
        "northing": "5932044.19",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kapellenstraße",
        "nr": 112
    },
    {
        "id": "H17374",
        "easting": "574445.386",
        "northing": "5931875.8",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Weg",
        "nr": 44
    },
    {
        "id": "H8980",
        "easting": "575315.023",
        "northing": "5931705.084",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 119
    },
    {
        "id": "H10636",
        "easting": "576227.904",
        "northing": "5932996.697",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Keitumer Weg",
        "nr": 24
    },
    {
        "id": "H19340",
        "easting": "572935.934",
        "northing": "5930551.127",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Alter Landweg",
        "nr": 12
    },
    {
        "id": "H10871",
        "easting": "575530.612",
        "northing": "5933663.432",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 34
    },
    {
        "id": "H11777",
        "easting": "575394.559",
        "northing": "5932756.294",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schümannweg",
        "nr": 46
    },
    {
        "id": "H19301",
        "easting": "573295.871",
        "northing": "5931877.981",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 92
    },
    {
        "id": "H10968",
        "easting": "575439.879",
        "northing": "5933532.354",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kantatenweg",
        "nr": 4
    },
    {
        "id": "H10346",
        "easting": "576207.788",
        "northing": "5932872.053",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kampener Stieg",
        "nr": 20
    },
    {
        "id": "H10904",
        "easting": "575790.515",
        "northing": "5933347.932",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Moosterhoop",
        "nr": 1
    },
    {
        "id": "H7803",
        "easting": "576288.905",
        "northing": "5931973.117",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 68
    },
    {
        "id": "H10738",
        "easting": "573404.579",
        "northing": "5931849.639",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 94
    },
    {
        "id": "H9052",
        "easting": "575585.032",
        "northing": "5931828.597",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 56
    },
    {
        "id": "H16530",
        "easting": "574837.923",
        "northing": "5933327.383",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Baxmannweg",
        "nr": 17
    },
    {
        "id": "H15763",
        "easting": "572578.803",
        "northing": "5929848.228",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "KLGV.604 Moorfleet",
        "nr": 59
    },
    {
        "id": "H8769",
        "easting": "575706.862",
        "northing": "5931349.955",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 44
    },
    {
        "id": "H8777",
        "easting": "575741.803",
        "northing": "5931436.994",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "August-Macke-Weg",
        "nr": 27
    },
    {
        "id": "H7771",
        "easting": "576560.368",
        "northing": "5931179.07",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Rahewinkel",
        "nr": 49
    },
    {
        "id": "H8802",
        "easting": "576112.936",
        "northing": "5931537.894",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 46
    },
    {
        "id": "H10931",
        "easting": "575797.589",
        "northing": "5933540.839",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Koolbarg",
        "nr": 30
    },
    {
        "id": "H16320",
        "easting": "573255.929",
        "northing": "5932739.381",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Billstedter Hauptstraße",
        "nr": 74
    },
    {
        "id": "H16323",
        "easting": "573154.142",
        "northing": "5932724.555",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Geesttwiete",
        "nr": 1
    },
    {
        "id": "H14289",
        "easting": "574350.996",
        "northing": "5932432.791",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Druckerstraße",
        "nr": 30
    },
    {
        "id": "H16452",
        "easting": "573995.318",
        "northing": "5933180.843",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 40
    },
    {
        "id": "H9524",
        "easting": "576349.999",
        "northing": "5933243.607",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 0
    },
    {
        "id": "H14294",
        "easting": "574428.353",
        "northing": "5932370.706",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Teubnerweg",
        "nr": 29
    },
    {
        "id": "H16533",
        "easting": "574840.579",
        "northing": "5933270.406",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Baxmannstraße",
        "nr": 6
    },
    {
        "id": "H11450",
        "easting": "576004.668",
        "northing": "5934350.893",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Barsbütteler Weg",
        "nr": 0
    },
    {
        "id": "H14215",
        "easting": "574572.578",
        "northing": "5932361.053",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Teubnerweg",
        "nr": 2
    },
    {
        "id": "H19534",
        "easting": "572910.595",
        "northing": "5932208.324",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 59
    },
    {
        "id": "H17405",
        "easting": "571667.53",
        "northing": "5932112.513",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 28
    },
    {
        "id": "H13880",
        "easting": "576241.288",
        "northing": "5933992.813",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "KLGV.154 Merkenstraße",
        "nr": 0
    },
    {
        "id": "H3880",
        "easting": "571763.273",
        "northing": "5932323.555",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 25
    },
    {
        "id": "H20375",
        "easting": "572131.39",
        "northing": "5931873.151",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 35
    },
    {
        "id": "H20856",
        "easting": "571690.211",
        "northing": "5932119.991",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Wöhlerstraße",
        "nr": 28
    },
    {
        "id": "H21325",
        "easting": "572247.836",
        "northing": "5932390.021",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 35
    },
    {
        "id": "H7564",
        "easting": "572533.633",
        "northing": "5931766.543",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Werner-Siemens-Straße",
        "nr": 57
    },
    {
        "id": "H10948",
        "easting": "572066.552",
        "northing": "5928322.421",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 309
    },
    {
        "id": "H9066",
        "easting": "576067.984",
        "northing": "5931697.124",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Oskar-Schlemmer-Straße",
        "nr": 17
    },
    {
        "id": "H16540",
        "easting": "574318.198",
        "northing": "5933398.908",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 68
    },
    {
        "id": "H10986",
        "easting": "571936.984",
        "northing": "5929485.163",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Brennerhof",
        "nr": 98
    },
    {
        "id": "H19510",
        "easting": "572715.796",
        "northing": "5932468.813",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 153
    },
    {
        "id": "H3866",
        "easting": "572110.769",
        "northing": "5932226.274",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 41
    },
    {
        "id": "H11018",
        "easting": "571741.853",
        "northing": "5929795.624",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Kirchenweg",
        "nr": 63
    },
    {
        "id": "H12234",
        "easting": "571985.626",
        "northing": "5929935.228",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 47
    },
    {
        "id": "H19489",
        "easting": "571571.613",
        "northing": "5932623.406",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 27
    },
    {
        "id": "H11005",
        "easting": "571413.84",
        "northing": "5929299.588",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 0
    },
    {
        "id": "H13651",
        "easting": "571714.172",
        "northing": "5928561.723",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 271
    },
    {
        "id": "H19324",
        "easting": "572968.221",
        "northing": "5930602.797",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Unterer Landweg",
        "nr": 93
    },
    {
        "id": "H12342",
        "easting": "572116.296",
        "northing": "5929761.94",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 83
    },
    {
        "id": "H13121",
        "easting": "571422.78",
        "northing": "5931786.087",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 28
    },
    {
        "id": "H10959",
        "easting": "571694.269",
        "northing": "5928497.73",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Brückenstraße",
        "nr": 3
    },
    {
        "id": "H13103",
        "easting": "571591.668",
        "northing": "5931455.681",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 2
    },
    {
        "id": "H19819",
        "easting": "573853.126",
        "northing": "5930990.36",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 43
    },
    {
        "id": "H19823",
        "easting": "573628.055",
        "northing": "5931329.814",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Billwerder Billdeich",
        "nr": 8
    },
    {
        "id": "H7579",
        "easting": "573021.723",
        "northing": "5930897.113",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 42
    },
    {
        "id": "H11847",
        "easting": "574969.027",
        "northing": "5932136.678",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 12
    },
    {
        "id": "H11841",
        "easting": "575094.484",
        "northing": "5932369.664",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 38
    },
    {
        "id": "H12954",
        "easting": "571960.44",
        "northing": "5930641.807",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 52
    },
    {
        "id": "H19485",
        "easting": "571416.002",
        "northing": "5932511.701",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 11
    },
    {
        "id": "H12802",
        "easting": "572121.493",
        "northing": "5931466.458",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 9
    },
    {
        "id": "H10736",
        "easting": "573583.523",
        "northing": "5931341.558",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Unterer Landweg",
        "nr": 4
    },
    {
        "id": "H19312",
        "easting": "573363.476",
        "northing": "5931096.694",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "KLGV.126 Unterer Landweg",
        "nr": 0
    },
    {
        "id": "H10989",
        "easting": "575182.28",
        "northing": "5933357.014",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glitzaweg",
        "nr": 23
    },
    {
        "id": "H12236",
        "easting": "576445.621",
        "northing": "5933251.478",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Glinder Straße",
        "nr": 0
    },
    {
        "id": "H7796",
        "easting": "576503.388",
        "northing": "5931681.321",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Große Holl",
        "nr": 16
    },
    {
        "id": "H17990",
        "easting": "574721.298",
        "northing": "5931750.601",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 87
    },
    {
        "id": "H9015",
        "easting": "575605.711",
        "northing": "5932072.461",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 42
    },
    {
        "id": "H8932",
        "easting": "575572.642",
        "northing": "5931542.598",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Havighorster Redder",
        "nr": 14
    },
    {
        "id": "H9549",
        "easting": "575780.301",
        "northing": "5932538.148",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 81
    },
    {
        "id": "H20498",
        "easting": "576948.503",
        "northing": "5931071.449",
        "plz": 22115,
        "ortsteil": "Lohbrügge",
        "str": "Steinbeker Grenzdamm",
        "nr": 68
    },
    {
        "id": "H10969",
        "easting": "571637.623",
        "northing": "5928622.531",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 259
    },
    {
        "id": "H46009",
        "easting": "575233.7396",
        "northing": "5932278.113",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 27
    },
    {
        "id": "H46017",
        "easting": "575156.721",
        "northing": "5932213.409",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 21
    },
    {
        "id": "H46019",
        "easting": "575092.056",
        "northing": "5932133.709",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 19
    },
    {
        "id": "H46021",
        "easting": "575031.446",
        "northing": "5932040.977",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "An der Glinder Au",
        "nr": 11
    },
    {
        "id": "H46027",
        "easting": "571980.147",
        "northing": "5929841.946",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Sandwisch",
        "nr": 67
    },
    {
        "id": "H46031",
        "easting": "571543.827",
        "northing": "5928485.091",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Hauptdeich",
        "nr": 2
    },
    {
        "id": "H46041",
        "easting": "574044.052",
        "northing": "5932772.816",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 103
    },
    {
        "id": "H46279",
        "easting": "575963.842",
        "northing": "5932106.499",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Pechstein-Straße",
        "nr": 31
    },
    {
        "id": "H46282",
        "easting": "575695.992",
        "northing": "5933033.669",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Rantumer Weg",
        "nr": 14
    },
    {
        "id": "H46295",
        "easting": "576397.591",
        "northing": "5931093.882",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Grenzdamm",
        "nr": 12
    },
    {
        "id": "H46307",
        "easting": "574342.493",
        "northing": "5932683.926",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Letternkamp",
        "nr": 9
    },
    {
        "id": "H46544",
        "easting": "575095.6414",
        "northing": "5932518.685",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 8
    },
    {
        "id": "H46545",
        "easting": "575373.375",
        "northing": "5933038.579",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinfurther Allee",
        "nr": 2
    },
    {
        "id": "H46596",
        "easting": "572622.697",
        "northing": "5930968.608",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Porgesring",
        "nr": 11
    },
    {
        "id": "H46768",
        "easting": "575447.7642",
        "northing": "5931401.382",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 141
    },
    {
        "id": "H46792",
        "easting": "571682.66",
        "northing": "5928401.391",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Brückenstraße",
        "nr": 15
    },
    {
        "id": "H47033",
        "easting": "575511.44",
        "northing": "5931343.44",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 147
    },
    {
        "id": "H47088",
        "easting": "576314.723",
        "northing": "5931800.201",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kleine Holl",
        "nr": 13
    },
    {
        "id": "H47553",
        "easting": "571105.35",
        "northing": "5930183.67",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 100
    },
    {
        "id": "H47726",
        "easting": "576435.9302",
        "northing": "5931791.279",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Strietkoppel",
        "nr": 13
    },
    {
        "id": "H47277",
        "easting": "571078.45",
        "northing": "5932190.62",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 2
    },
    {
        "id": "H47278",
        "easting": "571175.38",
        "northing": "5932143.5",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 2
    },
    {
        "id": "H47279",
        "easting": "571283.91",
        "northing": "5932093.21",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 7
    },
    {
        "id": "H47280",
        "easting": "571365.63",
        "northing": "5932055.42",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 11
    },
    {
        "id": "H47863",
        "easting": "573427.5572",
        "northing": "5929726.224",
        "plz": 22113,
        "ortsteil": "Billwerder",
        "str": "Dweerlandweg",
        "nr": 0
    },
    {
        "id": "H47926",
        "easting": "575825.86",
        "northing": "5931572.37",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Klinger-Straße",
        "nr": 23
    },
    {
        "id": "H47927",
        "easting": "576239.42",
        "northing": "5931460.77",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 18
    },
    {
        "id": "H48004",
        "easting": "575992.48",
        "northing": "5932008.897",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Pechstein-Straße",
        "nr": 21
    },
    {
        "id": "H46489",
        "easting": "575444.7407",
        "northing": "5933366.234",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 9
    },
    {
        "id": "H46490",
        "easting": "575505.7433",
        "northing": "5933298.478",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 3
    },
    {
        "id": "H47857",
        "easting": "576402.3757",
        "northing": "5934589.218",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hegenredder",
        "nr": 0
    },
    {
        "id": "H47889",
        "easting": "576312.5747",
        "northing": "5934352.495",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hegenredder",
        "nr": 0
    },
    {
        "id": "H47858",
        "easting": "576458.6771",
        "northing": "5934744.566",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Hegenredder",
        "nr": 0
    },
    {
        "id": "H48117",
        "easting": "571520.8941",
        "northing": "5928568.82",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Kneidenweg",
        "nr": 5
    },
    {
        "id": "H48136",
        "easting": "571021.2286",
        "northing": "5929577.954",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Holzhafenufer",
        "nr": 10
    },
    {
        "id": "H48198",
        "easting": "575445.32",
        "northing": "5931375.39",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Hauptstraße",
        "nr": 143
    },
    {
        "id": "H48199",
        "easting": "575994.59",
        "northing": "5931888.97",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Max-Pechstein-Straße",
        "nr": 7
    },
    {
        "id": "H48201",
        "easting": "572116.92",
        "northing": "5933181.4",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "H48202",
        "easting": "575108.54",
        "northing": "5932908.48",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 184
    },
    {
        "id": "H48203",
        "easting": "575335.63",
        "northing": "5932530.84",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Sonnenland",
        "nr": 55
    },
    {
        "id": "H48204",
        "easting": "572485.92",
        "northing": "5931546.8",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 40
    },
    {
        "id": "H48205",
        "easting": "572267.28",
        "northing": "5931044.9",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Moorfleeter Straße",
        "nr": 51
    },
    {
        "id": "H48207",
        "easting": "574672.64",
        "northing": "5932218.95",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 12
    },
    {
        "id": "H47925",
        "easting": "571596.929",
        "northing": "5931988.299",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 13
    },
    {
        "id": "H47923",
        "easting": "571423.2985",
        "northing": "5932024.006",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Borsigstraße",
        "nr": 16
    },
    {
        "id": "H48262",
        "easting": "572082.1674",
        "northing": "5929868.878",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 51
    },
    {
        "id": "H48309",
        "easting": "574162.5635",
        "northing": "5933292.449",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Archenholzstraße",
        "nr": 51
    },
    {
        "id": "H48384",
        "easting": "576847.3",
        "northing": "5931064.904",
        "plz": 22115,
        "ortsteil": "Lohbrügge",
        "str": "Steinbeker Grenzdamm",
        "nr": 62
    },
    {
        "id": "H48392",
        "easting": "571535.959",
        "northing": "5931438.947",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 4
    },
    {
        "id": "H48393",
        "easting": "572246.162",
        "northing": "5930898.638",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 25
    },
    {
        "id": "H48394",
        "easting": "572667.279",
        "northing": "5930623.964",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Bredowstraße",
        "nr": 33
    },
    {
        "id": "H48465",
        "easting": "576290.669",
        "northing": "5933177.025",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Oststeinbeker Weg",
        "nr": 126
    },
    {
        "id": "H48546",
        "easting": "574848.687",
        "northing": "5932527.984",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 61
    },
    {
        "id": "H48574",
        "easting": "574427.391",
        "northing": "5932959.718",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Honsbergenstraße",
        "nr": 5
    },
    {
        "id": "H48452",
        "easting": "571630.325",
        "northing": "5932005.823",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 0
    },
    {
        "id": "H48454",
        "easting": "571662.156",
        "northing": "5932012.179",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 0
    },
    {
        "id": "H48455",
        "easting": "571672.8665",
        "northing": "5932017.253",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 0
    },
    {
        "id": "H48881",
        "easting": "574761.691",
        "northing": "5933043.744",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Tabulatorweg",
        "nr": 6
    },
    {
        "id": "H48946",
        "easting": "576218.2627",
        "northing": "5932519.19",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "KLGV.148 Steinfurth",
        "nr": 170
    },
    {
        "id": "H48954",
        "easting": "575472.949",
        "northing": "5933555.886",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schlangenkoppel",
        "nr": 23
    },
    {
        "id": "H48959",
        "easting": "575946.719",
        "northing": "5931587.502",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Ittenstraße",
        "nr": 21
    },
    {
        "id": "H48917",
        "easting": "576209.201",
        "northing": "5931974.302",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 25
    },
    {
        "id": "H49055",
        "easting": "570880.175",
        "northing": "5931318.97",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Andreas-Meyer-Straße",
        "nr": 8
    },
    {
        "id": "H49136",
        "easting": "571627.9473",
        "northing": "5928392.136",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Schleusenweg",
        "nr": 22
    },
    {
        "id": "H49147",
        "easting": "571909.045",
        "northing": "5931510.001",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 7
    },
    {
        "id": "H49165",
        "easting": "574991.51",
        "northing": "5933633.286",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 21
    },
    {
        "id": "",
        "easting": "573762.8087",
        "northing": "5932558.252",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 0
    },
    {
        "id": "H49226",
        "easting": "576481.0187",
        "northing": "5932250.599",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Am Mühlenbach",
        "nr": 27
    },
    {
        "id": "H49230",
        "easting": "575851.3699",
        "northing": "5933303.666",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaltenbergen",
        "nr": 5
    },
    {
        "id": "H49234",
        "easting": "574937.9454",
        "northing": "5931718.445",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "An der Steinbek",
        "nr": 3
    },
    {
        "id": "H49312",
        "easting": "574549.339",
        "northing": "5932573.214",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Letternkamp",
        "nr": 47
    },
    {
        "id": "H49381",
        "easting": "574938.065",
        "northing": "5933586.552",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 13
    },
    {
        "id": "H49390",
        "easting": "574984.5449",
        "northing": "5933634.215",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 21
    },
    {
        "id": "H49471",
        "easting": "572839.318",
        "northing": "5932000.923",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 72
    },
    {
        "id": "H49470",
        "easting": "572983.087",
        "northing": "5931960.599",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 82
    },
    {
        "id": "H49469",
        "easting": "573133.271",
        "northing": "5931917.921",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 86
    },
    {
        "id": "H",
        "easting": "573756.4619",
        "northing": "5932172.288",
        "plz": 22113,
        "ortsteil": "Billstedt",
        "str": "Niederschleems",
        "nr": 0
    },
    {
        "id": "H49624",
        "easting": "573816.6481",
        "northing": "5933011.093",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 59
    },
    {
        "id": "H49717",
        "easting": "572013.524",
        "northing": "5932253.324",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 37
    },
    {
        "id": "H49709",
        "easting": "575930.6",
        "northing": "5932988.869",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Knivsbergweg",
        "nr": 1
    },
    {
        "id": "H49664",
        "easting": "576341.7793",
        "northing": "5933328.598",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Grenzwinkel",
        "nr": 0
    },
    {
        "id": "H49845",
        "easting": "572301.703",
        "northing": "5932158.277",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 52
    },
    {
        "id": "H49847",
        "easting": "572493.877",
        "northing": "5932104.803",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 60
    },
    {
        "id": "H49874",
        "easting": "576416.662",
        "northing": "5930819.146",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Asbrookdamm",
        "nr": 25
    },
    {
        "id": "H49875",
        "easting": "576508.314",
        "northing": "5930756.24",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Asbrookdamm",
        "nr": 24
    },
    {
        "id": "H49876",
        "easting": "576281.2809",
        "northing": "5930953.631",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Asbrookdamm",
        "nr": 40
    },
    {
        "id": "H49877",
        "easting": "574635.229",
        "northing": "5932519.266",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 66
    },
    {
        "id": "H49878",
        "easting": "576311.8857",
        "northing": "5931061.504",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Asbrookdamm",
        "nr": 44
    },
    {
        "id": "H49879",
        "easting": "576194.239",
        "northing": "5931260.407",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kandinskyallee",
        "nr": 2
    },
    {
        "id": "H49890",
        "easting": "572679.709",
        "northing": "5932052.99",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Liebigstraße",
        "nr": 67
    },
    {
        "id": "H49981",
        "easting": "574995.021",
        "northing": "5931839.289",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Mümmelmannsberg",
        "nr": 5
    },
    {
        "id": "H49997",
        "easting": "574307.454",
        "northing": "5932126.681",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Mehrenskamp",
        "nr": 17
    },
    {
        "id": "H50005",
        "easting": "572162.238",
        "northing": "5931440.688",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 10
    },
    {
        "id": "H50006",
        "easting": "571640.849",
        "northing": "5930912.276",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Halskestraße",
        "nr": 40
    },
    {
        "id": "H50008",
        "easting": "573007.5971",
        "northing": "5932181.349",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Berzeliusstraße",
        "nr": 72
    },
    {
        "id": "H50009",
        "easting": "574882.193",
        "northing": "5932349.023",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Steinbeker Marktstraße",
        "nr": 44
    },
    {
        "id": "H50021",
        "easting": "574712.3662",
        "northing": "5932820.375",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Merkenstraße",
        "nr": 1
    },
    {
        "id": "H49729",
        "easting": "575110.6367",
        "northing": "5932822.416",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Horst-Böttjer-Weg",
        "nr": 39
    },
    {
        "id": "H49727",
        "easting": "575154.924",
        "northing": "5932855.614",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Horst-Böttjer-Weg",
        "nr": 5
    },
    {
        "id": "H49733",
        "easting": "575098.019",
        "northing": "5932757.863",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Horst-Böttjer-Weg",
        "nr": 30
    },
    {
        "id": "H49730",
        "easting": "575184.816",
        "northing": "5932798.602",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Horst-Böttjer-Weg",
        "nr": 14
    },
    {
        "id": "H50165",
        "easting": "571964.821",
        "northing": "5931499.115",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Pinkertweg",
        "nr": 8
    },
    {
        "id": "H49732",
        "easting": "575176.203",
        "northing": "5932709.826",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Marianne-Timm-Weg",
        "nr": 31
    },
    {
        "id": "H50227",
        "easting": "575104.1729",
        "northing": "5932682.152",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Landjägerstieg",
        "nr": 28
    },
    {
        "id": "H49731",
        "easting": "575216.487",
        "northing": "5932748.434",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Marianne-Timm-Weg",
        "nr": 27
    },
    {
        "id": "H49728",
        "easting": "575044.7288",
        "northing": "5932804.447",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Wittdüner Weg",
        "nr": 9
    },
    {
        "id": "H50369",
        "easting": "575731.6466",
        "northing": "5931901.093",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Kollwitzring",
        "nr": 72
    },
    {
        "id": "H50247",
        "easting": "575850.541",
        "northing": "5933367.526",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 0
    },
    {
        "id": "H50395",
        "easting": "575296.5716",
        "northing": "5933000.581",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Möllner Landstraße",
        "nr": 206
    },
    {
        "id": "H50095",
        "easting": "574587.518",
        "northing": "5932060.957",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Brockhausweg",
        "nr": 4
    },
    {
        "id": "H50525",
        "easting": "571907.619",
        "northing": "5933308.105",
        "plz": 22113,
        "ortsteil": "Horn",
        "str": "Kolumbusstraße",
        "nr": 8
    },
    {
        "id": "",
        "easting": "573813.858",
        "northing": "5932985.121",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schleemer Weg",
        "nr": 34
    },
    {
        "id": "H50464",
        "easting": "573149.548",
        "northing": "5932347.93",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 210
    },
    {
        "id": "H50463",
        "easting": "573150.291",
        "northing": "5932347.716",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 210
    },
    {
        "id": "H50466",
        "easting": "572829.479",
        "northing": "5932462.971",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 165
    },
    {
        "id": "H50676",
        "easting": "575435.342",
        "northing": "5931538.909",
        "plz": 22115,
        "ortsteil": "Billstedt",
        "str": "Godenwind",
        "nr": 2
    },
    {
        "id": "H50703",
        "easting": "571813.1483",
        "northing": "5932830.8",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 55
    },
    {
        "id": "H50704",
        "easting": "575706.272",
        "northing": "5933252.872",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Kaltenbergen",
        "nr": 2
    },
    {
        "id": "H50710",
        "easting": "571405.7869",
        "northing": "5931634.669",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Grusonstraße",
        "nr": 53
    },
    {
        "id": "H50732",
        "easting": "574757.976",
        "northing": "5933520.128",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Reinskamp",
        "nr": 3
    },
    {
        "id": "H50465",
        "easting": "572967.19",
        "northing": "5932416.532",
        "plz": 22113,
        "ortsteil": "Billbrook",
        "str": "Billbrookdeich",
        "nr": 171
    },
    {
        "id": "H50888",
        "easting": "572285.459",
        "northing": "5928180.704",
        "plz": 22113,
        "ortsteil": "Moorfleet",
        "str": "Moorfleeter Deich",
        "nr": 333
    },
    {
        "id": "H50900",
        "easting": "575296.24",
        "northing": "5932940.015",
        "plz": 22117,
        "ortsteil": "Billstedt",
        "str": "Schümannweg",
        "nr": 12
    }
]
    .map(h => {
        return {
            'name': h.id,
            'position': utm.convertUtmToLatLng(h.easting, h.northing, 32, 'U'),
            'adresse': h.str + ' ' + h.nr,
            'ort': h.plz + ' ' + h.ortsteil,
        };
    })
    .sort((a, b) => new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'}).compare(a.adresse, b.adresse))
    .filter(h => !!h.name)